import React, { Component } from 'react';
import ExportListTable from './ExportListTable';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ExportStatistics from '../exportStatistics/ExportStatistics';
import { withRouter } from 'utils/withRouter';
import Widget from 'components/Widget/Widget';
import { Row, Col } from 'reactstrap';
class ExportPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.Export'] ? (
          <>
            <div style={{ marginBottom: '5%' }}>
              <Widget
                style={{
                  marginTop: '20px',
                  width: '100%'
                }}
              >
                <Row>
                  <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                    <div className={'d-flex align-items-center'}>
                      <h3 className={'first-headline-text me-4 mb-0'}>Export Jobs</h3>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <ExportListTable navigate={this.props.navigate} />
                </Row>
              </Widget>
            </div>

            <div>
              <ExportStatistics navigate={this.props.navigate} />
            </div>
          </>
        ) : (
          <Navigate to='/no-access' replace />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}

export default withRouter(connect(mapStateToProps)(ExportPage));
