import { defaultOptions } from 'services/userService';
import config from 'config';
import Errors from 'components/FormItems/error/errors';
import { store } from 'index';
import { toast } from 'react-toastify';

async function doGetContainers(legalHoldType) {
  const baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold/GetContainers?legalHoldType=${legalHoldType}`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });
  const data = await response.json();
  return data;
}

async function doGetLegalHoldNames(legalCaseId) {
  const currentUser = store.getState().auth.currentUser;
  const baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold/GetLegalHoldNames?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });
  const data = await response.json();
  return data;
}

async function doCreateFileLH(data, expireonUser) {
  const baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold/CalculateStatisitcsAndSaveFileLh`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
  return response.json();
}

async function doConfirmFileLH(data, expireonUser) {
  const baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold/SaveFileLegalHold`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
  return response.json();
}

async function discardLegalHold(legalCaseId, legalHoldId, expireonUser) {
  const { name: firstName, surName: lastName, email, userName } = expireonUser || {};
  let loggedUserString = { firstName, lastName, email, userName };
  const baseUrl = `${config.externalApi}/legal/api/OneDriveLegalHold/${legalHoldId}/${legalCaseId}`;
  await fetch(baseUrl, {
    ...defaultOptions,
    method: 'DELETE',
    body: JSON.stringify(loggedUserString)
  });
}
async function doGetCustodianIdentities(custodianId) {
  const response = await fetch(
    `${config.externalApi}/legal/api/Custodian/GetCustodianIdentities?custodianId=${custodianId}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );
  const data = await response.json();
  return data;
}

async function doGetAllCustodiansData(custodiansList) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/GetAllCustodiansData`,
    {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(custodiansList)
    }
  );
  const data = await response.json();
  return data;
}

async function doFindLegalHold(id, expireonUser) {
  const expireonUserString = JSON.stringify(expireonUser);
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/${id}/${expireonUserString}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );
  const data = await response.json();
  return data;
}

async function getCustodians(pageNumber, pageSize, searchString) {
  let baseUrl = `${config.externalApi}/legal/api/Custodian/GetCustodians?`;
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });
  const data = await response.json();
  return data;
}

async function doGetLegalHoldStats(legalHoldId) {
  const response = await fetch(
    `${config.externalApi}/legal/api/OneDriveLegalHold/${legalHoldId}/Stats`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );
  const data = await response.json();
  return data;
}

const actions = {
  doFindContainers: (legalHoldType) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CONTAINERS_STARTED'
      });
      doGetContainers(legalHoldType).then((response) => {
        dispatch({
          type: 'FILE_LEGALHOLD_FORM_CONTAINERS_SUCCESS',
          payload: response
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CONTAINERS_ERROR'
      });
    }
  },
  doFindLegalHoldNames: (legalCaseId) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_FIND_NAMES_STARTED'
      });

      doGetLegalHoldNames(legalCaseId).then((response) => {
        dispatch({
          type: 'FILE_LEGALHOLD_FORM_FIND_NAMES_SUCCESS',
          payload: response
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_FIND_NAMES_ERROR'
      });
    }
  },
  doOpenFileLH: () => async (dispatch) => {
    dispatch({
      type: 'FILE_LEGALHOLD_FORM_OPEN_CONFIRM'
    });
  },
  doCloseFileLH: () => async (dispatch) => {
    dispatch({
      type: 'FILE_LEGALHOLD_FORM_CLOSE_CONFIRM'
    });
  },
  doCreate: (values, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CREATE_STARTED'
      });

      await doCreateFileLH(values, expireonUser).then((res) => {
        dispatch({
          type: 'FILE_LEGALHOLD_FORM_CREATE_SUCCESS',
          payload: res
        });
        toast.success('Legal Hold created!', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CREATE_ERROR'
      });
    }
  },
  doConfirmFileLH: (values, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CONFIRM_STARTED'
      });

      doConfirmFileLH(values, expireonUser).then((res) => {
        dispatch({
          type: 'FILE_LEGALHOLD_FORM_CONFIRM_SUCCESS'
        });
        toast.success('Legal Hold confirmed!', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_CONFIRM_ERROR'
      });
    }
  },
  doGetCustodianIdentities: (custodianId) => async (dispatch) => {
    try {
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_STARTED'
      });

      const response = await doGetCustodianIdentities(custodianId);
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_SUCCESS',
        payload: {
          userIdentities: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CUSTODIAN_IDENTITIES_LIST_FETCH_ERROR'
      });
    }
  },
  doGetAllCustodiansData: (custodiansList) => async (dispatch) => {
    try {
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_STARTED'
      });

      const response = await doGetAllCustodiansData(custodiansList);
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          allCustodiansList: response?.result,
          allCustodiansCount: response?.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ALL_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doSetSelectedCustodians: (selectedCustodians) => async (dispatch) => {
    try {
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_STARTED'
      });
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          selectedCustodians: selectedCustodians
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SELECTED_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doSetSelectedExternalCustodians: (selectedExternalCustodians) => async (dispatch) => {
    try {
      dispatch({
        type: 'SELECTED__EXTERNAL_CUSTODIANS_LIST_FETCH_STARTED'
      });
      dispatch({
        type: 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          selectedExternalCustodians: selectedExternalCustodians
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doSetCurrentStep: (currentStep) => async (dispatch) => {
    dispatch({
      type: 'LEGAL_HOLD_FORM_CURRENT_STEP',
      payload: currentStep
    });
  },
  doOpenInternalSelection: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_OPEN'
    });
  },
  doCloseInternalSelection: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_CLOSE'
    });
  },
  doOpenCustodianConfirmation: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_OPEN'
    });
  },
  doCloseCustodianConfirmation: () => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_CLOSE'
    });
  },
  doSetSelectedCustodiansCount: (count) => async (dispatch) => {
    dispatch({
      type: 'LEGALHOLD_FORM_SET_SELECTED_CUSTODIANS_COUNT',
      payload: {
        count
      }
    });
  },
  doDiscardLegalHold: (legalCaseId, legalHoldId, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALHOLD_DISCARD_STARTED'
      });
      await discardLegalHold(legalCaseId, legalHoldId, expireonUser);
      dispatch({
        type: 'LEGALHOLD__DISCARD_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALHOLD_DISCARD_ERROR'
      });
    }
  },
  doFind: (id, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_FIND_STARTED'
      });
      doFindLegalHold(id, expireonUser).then((response) => {
        dispatch({
          type: 'FILE_LEGALHOLD_FORM_FIND_SUCCESS',
          payload: response
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGALHOLD_FORM_FIND_ERROR'
      });
    }
  },
  doGetCustodians: (pageNumber, pageSize, searchString) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_CUSTODIANS_LIST_FETCH_STARTED'
      });

      const response = await getCustodians(pageNumber, pageSize, searchString);
      dispatch({
        type: 'FILE_CUSTODIANS_LIST_FETCH_SUCCESS',
        payload: {
          custodians: response.result,
          custodiansCount: response.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_CUSTODIANS_LIST_FETCH_ERROR'
      });
    }
  },
  doGetLegalHoldStatistics: (legalHoldId) => async (dispatch) => {
    try {
      dispatch({
        type: 'ONE_DRIVE_FORM_GET_STATISTICS_STARTED'
      });
      doGetLegalHoldStats(legalHoldId).then((response) => {
        dispatch({
          type: 'ONE_DRIVE_FORM_GET_STATISTICS_SUCCESS',
          payload: response
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ONE_DRIVE_FORM_GET_STATISTICS_ERROR'
      });
    }
  }
};
export default actions;
