import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { store } from '../../index';
import { toast } from 'react-toastify';
import { downloadService } from 'services/downloadService';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';
async function list(searchString, pageNumber, pageSize, legalCaseId, sortOrder, exportJobType) {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = `${config.externalApi}/legal/api/ExportJob?loggedUser=${currentUser.userName}&`;
  if (pageNumber !== undefined) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (legalCaseId !== undefined && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += `isLegalAdmin=` + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (exportJobType !== undefined && exportJobType !== null && exportJobType !== '') {
    baseUrl += `exportJobType=` + encodeURIComponent('' + exportJobType) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, { ...defaultOptions, method: 'GET' });

  const data = await response.json();
  return data;
}

async function retryFailedItems(legalCaseId, exportJobId, currentUser) {
  let baseUrl = `${config.externalApi}/legal/api/ExportJob/ExportRetryFailedItems?`;
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };

  if (legalCaseId !== undefined && legalCaseId !== '') {
    baseUrl += 'legalCaseID=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (exportJobId !== undefined && exportJobId !== '') {
    baseUrl += 'exportJobId=' + encodeURIComponent('' + exportJobId) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await fetch(baseUrl, {
    headers: defaultOptions.headers,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

async function resetQueuedItems(exportJobId, currentUser) {
  let baseUrl = `${config.externalApi}/legal/api/ExportJob/ExportResetQueuedItems?`;
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };

  if (exportJobId !== undefined && exportJobId !== '') {
    baseUrl += 'exportJobId=' + encodeURIComponent('' + exportJobId) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await fetch(baseUrl, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

async function getExportPolicyStats(sortOrder, pageNumber, pageSize, searchString) {
  let baseUrl = `${config.externalApi}/legal/api/ExportJob/GetExportPolicyStats?`;
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (pageNumber !== undefined && pageNumber !== '') {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== '') {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '' && searchString !== null) {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, { ...defaultOptions, method: 'GET' });

  const data = await response.json();
  return data;
}

async function exportToCSV(
  downloadUrl,
  currentUser,
  legalCaseId,
  searchString,
  columnHeaders,
  exportJobType
) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (currentUser?.userName !== undefined && currentUser?.userName !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + currentUser?.userName) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += `isLegalAdmin=` + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (exportJobType !== undefined && exportJobType !== null && exportJobType !== '') {
    baseUrl += `exportJobType=` + encodeURIComponent('' + exportJobType) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  await downloadService(baseUrl, 'POST', data);
}

async function exportToPDF(
  downloadUrl,
  currentUser,
  legalCaseId,
  searchString,
  columnHeaders,
  exportJobType
) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);

  let baseUrl = downloadUrl;
  if (legalCaseId !== undefined && legalCaseId !== null && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (currentUser?.userName !== undefined && currentUser?.userName !== null) {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + currentUser?.userName) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += `isLegalAdmin=` + encodeURIComponent('' + legalAdmin) + '&';
  }
  if (exportJobType !== undefined && exportJobType !== null && exportJobType !== '') {
    baseUrl += `exportJobType=` + encodeURIComponent('' + exportJobType) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  await downloadService(baseUrl, 'POST', data);
}
async function getExportJob(exportJobId, expireonUser) {
  const expireonUserstring = JSON.stringify(expireonUser);
  const response = await fetch(
    `${config.externalApi}/legal/api/ExportJob/${exportJobId}/${expireonUserstring}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'EXPORT_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.searchString || '',
          filter.pageNumber || 1,
          filter.pageSize || 5,
          filter.legalCaseId,
          filter.sortOrder,
          filter.exportJobType
        );
        dispatch({
          type: 'EXPORT_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.exportJobs,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_LIST_FETCH_ERROR'
        });
      }
    },
  doRetryFailedItems: (legalCaseId, exportJobId, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_RETRY_FAILED_ITEMS_STARTED'
      });
      await retryFailedItems(legalCaseId, exportJobId, currentUser);
      dispatch({
        type: 'EXPORT_RETRY_FAILED_ITEMS_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_RETRY_FAILED_ITEMS_ERROR'
      });
    }
  },
  doResetFailedItems: (exportJobId, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_RESET_QUEUED_ITEMS_STARTED'
      });
      await resetQueuedItems(exportJobId, currentUser);
      toast.success('Reset process has started', { icon: false });
      dispatch({
        type: 'EXPORT_RESET_QUEUED_ITEMS_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_RESET_QUEUED_ITEMS_ERROR'
      });
    }
  },
  doGetExportPolicyStats:
    (filter, keepPagination = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_POLICY_STATS_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await getExportPolicyStats(
          filter.sortOrder,
          filter.pageNumber,
          filter.pageSize,
          filter.searchString
        );
        dispatch({
          type: 'EXPORT_POLICY_STATS_FETCH_SUCCESS',
          payload: {
            exportStatsList: response.exportPolicyStats,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_POLICY_STATS_FETCH_ERROR'
        });
      }
    },
  handleExportToCSV:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders, exportJobType) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_CSV_STARTED'
        });
        exportToCSV(
          downloadUrl,
          currentUser,
          legalCaseId,
          searchString,
          columnHeaders,
          exportJobType
        ).then(() => {
          dispatch({
            type: 'EXPORT_TO_CSV_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, currentUser, legalCaseId, searchString, columnHeaders, exportJobType) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_PDF_STARTED'
        });
        exportToPDF(
          downloadUrl,
          currentUser,
          legalCaseId,
          searchString,
          columnHeaders,
          exportJobType
        ).then(() => {
          dispatch({
            type: 'EXPORT_TO_PDF_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_PDF_ERROR'
        });
      }
    },
  doFetchExportJob: (id, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_JOB_FETCH_STARTED'
      });
      getExportJob(id, expireonUser).then((res) => {
        dispatch({
          type: 'EXPORT_JOB_FETCH_SUCCESS',
          payload: {
            exportJob: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_JOB_FETCH_ERROR'
      });
    }
  },
  doShowTable: () => async (dispatch) => {
    dispatch({
      type: 'EXPORT_LIST_SHOW_TABLE_CONFIRM'
    });
  },
  doHideTable: () => async (dispatch) => {
    dispatch({
      type: 'EXPORT_LIST_HIDE_TABLE_CONFIRM'
    });
  }
};

export default { ...actions, list };
