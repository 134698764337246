import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/OneDrive/OneDrivePreviewActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import actionsLC from 'actions/LegalCase/LegalCaseFormActions';
import plusIcon from 'images/icons/plus.svg';
import { FormattingService } from 'utils/sizeFormatting';
import { toast } from 'react-toastify';
import formActions from 'actions/OneDrive/OneDriveFormActions';
import OneDriveFormPage from '../form/OneDriveFormPage';
import Pagination from 'react-js-pagination';
import styles from '../../LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as searchDataFormat from 'components/CRUD/Search/list/SearchDataFormatters';
import { customCloseBtn } from 'actions/common';
import { GiConfirmed } from 'react-icons/gi';
import chatListStyle from '../../LegalHold/chatLegalHold/ChatLegalHoldList.module.scss';
import { CgProfile } from 'react-icons/cg';
import { GrOnedrive } from 'react-icons/gr';
import listActions from 'actions/LegalHold/LegalHoldListActions';
import trashIcon from 'images/icons/trash.svg';

class OneDriveTilesView extends Component {
  intervalID = 0;
  interval = 0;
  state = {
    isIndexing: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 3,
    newRows: [],
    searchString: '',
    legalHoldId: '',
    expandExport: false
  };

  openFormModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenFileLH());
  }

  closeFormModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseFileLH());
  }

  actionFormatter(cell, row) {
    const { navigate, permissions, caseStatus } = this.props;
    return (
      <div className='d-flex justify-content-end align-items-center w-100'>
        {permissions['LegalUI.LegalHold'] && (
          <button
            className='btn text-white first-body-text d-flex align-items-center me-2'
            type='button'
            onClick={() => {
              navigate(`/app/OneDrive/Preview/${row.legalHoldId}`);
            }}
          >
            <GrOnedrive style={{ color: 'white' }} className={'me-2'} />
            <p className={'mb-0'}>Preview</p>
          </button>
        )}
        {caseStatus !== config.caseStatus.Closed && (
          <button
            className='btn text-white first-body-text d-flex align-items-center ms-2'
            type='button'
            onClick={() => this.handleDiscardLegalHold(row)}
          >
            <img src={trashIcon} alt={'sync'} width={'14px'} height={'14px'} className={'me-2 '} />
            <p className={'mb-0'}>Discard</p>
          </button>
        )}
      </div>
    );
  }

  refreshTable = () => {
    const { dispatch, navigate } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(actionsLC.doGetStats(this.props.legalCaseId, navigate));
      dispatch(
        actions.doFetchFilesLH({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetchFilesLH({
        searchString: this.state.searchString,
        legalCaseId: this.props.legalCaseId,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetchFilesLH({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearInterval(this.interval);
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetchFilesLH({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleDiscardLegalHold = async (item) => {
    const { dispatch } = this.props;
    const legalCaseId = this.props.legalCaseId;
    const legalHoldId = item?.legalHoldId;
    await dispatch(
      formActions.doDiscardLegalHold(legalCaseId, legalHoldId, this.props?.currentUser)
    ).then(() => {
      toast.success('Legal Hold discarded', { icon: false });
      dispatch(
        actions.doFetchFilesLH({
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      );
    });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  showTable() {
    const { dispatch } = this.props;
    dispatch(listActions.doShowTable());
  }

  columnsToExport = [
    'legalHoldName',
    'legalHoldDescription',
    'creationDate',
    'itemSizeInBytes',
    'itemCount',
    'finallyFailedItemsCount',
    'legalHoldConfirmed'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/OneDriveLegalHold/ExportLegalHolds?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/OneDriveLegalHold/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  render() {
    const toReadableSizeFormat = new FormattingService();

    return (
      <div>
        <Row>
          <Col sm={12}>
            <>
              <Row key={'table-part'}>
                <Col style={{ marginTop: '8px' }}>
                  <div className={'d-flex align-items-center'}>
                    {this.props.permissions['LegalUI.LegalHold.FileLegalHold'] &&
                      this.props?.caseStatus !== config.caseStatus.Closed && (
                        <button
                          className='btn first-body-text d-flex align-items-center me-3'
                          type='button'
                          onClick={() => {
                            this.openFormModal();
                          }}
                        >
                          <img src={plusIcon} alt={'plus'} className={'me-2'} />
                          <p className={'mb-0'}>New file</p>
                        </button>
                      )}
                  </div>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5px'
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                  <span>
                    <button
                      className='btn fw-semi-bold d-flex align-items-center'
                      type='button'
                      onClick={() => this.showTable()}
                      style={{ height: '37px', marginRight: '5px' }}
                    >
                      <FontAwesomeIcon icon={faTable} />
                    </button>
                  </span>
                  <span>
                    <input
                      type='search'
                      placeholder={'Search'}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleSearch();
                        }
                      }}
                      style={{
                        border: '0.5px solid #0046b1',
                        borderRadius: '3px'
                      }}
                      className={'form-control search-input me-5 w-200'}
                      value={this.state.searchString}
                      onChange={(e) => {
                        this.setState({
                          searchString: e.target.value
                        });
                      }}
                    />
                  </span>
                  <span>
                    <button
                      className='btn search-button-with-icon me-2 ms-10'
                      type='button'
                      onClick={this.handleSearch}
                      style={{ height: '37px', width: '37px' }}
                    >
                      <img
                        title={'search'}
                        alt={'search'}
                        width={14}
                        height={14}
                        src={searchIcon}
                      />
                    </button>
                  </span>
                </Col>
              </Row>
            </>
          </Col>
        </Row>
        <Row sm={12} className={`mt-4 ${chatListStyle.widgetRow}`}>
          {this.state.newRows?.length ? (
            this.state.newRows?.map((item) => (
              <Col sm={4} key={item.legalHoldId} className={chatListStyle.widget}>
                <Widget className={chatListStyle.mailWidget} style={{ height: '400px' }}>
                  <div className={chatListStyle.cardHeaderContainer}>
                    <div>
                      <p className={`${chatListStyle.cardHeader} first-headline-text`}>
                        {item.legalHoldName}
                      </p>
                      <p className='d-flex'>
                        <span className={`${chatListStyle.filtersText} first-subtitle-text`}>
                          Description:
                        </span>
                        <span style={{ fontSize: '14px', marginTop: '2px' }}>
                          {item.legalHoldDescription}
                        </span>
                      </p>
                    </div>
                    {item.legalHoldConfirmed && (
                      <p className={chatListStyle.headerIcon}>
                        <GiConfirmed className={chatListStyle.confirmIcon} />
                      </p>
                    )}
                  </div>

                  <div className='w-100'>
                    <p className={`${chatListStyle.subHeaders} first-subtitle-text`}>Filter by</p>
                    <p className='mb-0 d-flex'>
                      <p className={`${chatListStyle.filtersText} first-subtitle-text mb-0`}>
                        Date:
                      </p>
                      <p className='mb-0'>
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateFrom)}
                        </span>
                        {' - '}
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateTo)}
                        </span>
                      </p>
                    </p>
                  </div>
                  <div>
                    <p className={`${chatListStyle.subHeaders} first-subtitle-text mt-3`}>
                      Results
                    </p>
                    <div className='w-100 d-flex mb-0 first-subtitle-text'>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.filtersText}>Items:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.legalHoldStatistics?.itemCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item.legalHoldStatistics.itemCount
                              )}`
                            : 0}
                        </p>
                      </p>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Size:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.legalHoldStatistics?.itemSizeInBytes
                            ? `${dataFormat.sizeFormatter(
                                item.legalHoldStatistics.itemSizeInBytes
                              )}`
                            : '0.00 B'}
                        </p>
                      </p>
                    </div>
                    <div className='w-100 d-flex mb-0 first-subtitle-text'>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.filtersText}>Items Indexed:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.legalHoldStatistics?.indexedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item.legalHoldStatistics?.indexedItemsCount
                              )}`
                            : 0}
                        </p>
                      </p>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Index Failures:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.legalHoldStatistics?.finallyFailedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item.legalHoldStatistics.finallyFailedItemsCount
                              )}`
                            : 0}
                        </p>
                      </p>
                    </div>
                  </div>
                  <p className='mt-4'>
                    <span className='first-subtitle-text me-2'>
                      <CgProfile className={chatListStyle.confirmIcon} />
                    </span>
                    <span className='me-1 first-body-text'>{item?.expireonUser?.firstName}</span>
                    <span className='me-1 first-body-text'>{item?.expireonUser?.lastName}</span>
                    <span className='first-body-text'>
                      ({dataFormat.dateTimeFormatter(item.creationDate)})
                    </span>
                  </p>
                  <p className='d-flex justify-content-center align-items-center mb-0'>
                    {this.actionFormatter(item.legalHoldId, item)}
                  </p>
                </Widget>
              </Col>
            ))
          ) : (
            <div
              style={{ height: this.state.newRows?.length ? 500 : 'auto' }}
              className={'d-flex w-100 justify-content-center align-items-center'}
            >
              <p className={'text-white'}>No data available</p>
            </div>
          )}
        </Row>
        <Row>
          <Col style={{ marginTop: '3px' }}>
            <p>Total: {this.state.totalCount}</p>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.state.totalCount ? (
              <Pagination
                totalItemsCount={this.state.totalCount}
                onChange={this.handlePageChange}
                activePage={this.state.pageNumber}
                itemsCountPerPage={this.state.pageSize}
                pageRangeDisplayed={5}
                prevPageText={'<'}
                nextPageText={'>'}
                firstPageText={'<<'}
                lastPageText={'>>'}
                linkClassFirst={styles.paginationNext}
                linkClassPrev={styles.paginationNext}
                linkClassNext={styles.paginationNext}
                linkClassLast={styles.paginationNext}
                linkClass={styles.pageLink}
                activeLinkClass={styles.activeLinkClass}
              />
            ) : null}
            <Dropdown
              isOpen={this.state.paginationModalOpen}
              toggle={() =>
                this.setState({
                  paginationModalOpen: !this.state.paginationModalOpen
                })
              }
              className={styles.pageSizeDropdown}
            >
              <DropdownToggle className='bg-transparent text-white d-flex'>
                <p style={{ marginBottom: '3px', marginRight: '2px' }} className='first-body-text'>
                  {this.state.pageSize}
                </p>
                <img
                  src={caretDown}
                  alt={'uncollapsed'}
                  width='20px'
                  height='20px'
                  className='ms-2'
                />
              </DropdownToggle>
              <DropdownMenu className='dropdown-position-fixed'>
                {[3, 6, 9].map((item) => (
                  <DropdownItem
                    className={styles.dropdownItem}
                    key={item}
                    onClick={() => {
                      this.setState({
                        pageSize: item,
                        paginationModalOpen: false,
                        pageNumber: 1
                      });
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <Modal
          size='lg'
          isOpen={this.props.openFileModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFormModal())}>
            <p className='second-headline-text'>Create Legal Hold</p>
          </ModalHeader>
          <ModalBody>
            <OneDriveFormPage
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.OneDrive.list.rows,
    permissions: store.auth.permissions,
    count: store.OneDrive.list.count,
    currentUser: store.auth.currentUser,
    openFileModal: store.OneDrive.form.openFileModal,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme,
    legalCase: store.LegalCase.form.record
  };
}

export default connect(mapStateToProps)(OneDriveTilesView);
