import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExportJobForm from './ExportJobForm';
import actions from '../../../../actions/Export/ExportFormActions';
import { withRouter } from 'utils/withRouter';

class ExportJobFormPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doFindExportPolicies(this.props?.exportJobType));
    dispatch(actions.doFindLegalHolds(this.props.legalCaseId, this.props?.exportJobType));
    dispatch(actions.doFindContentSearches(this.props.legalCaseId));
    dispatch(actions.doFindExportJobNames(this.props.legalCaseId, this.props?.exportJobType));
  }

  doSubmit = (data) => {
    const { dispatch } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    dispatch(
      actions.doCreate({
        ...data,
        legalCaseId: this.props.legalCaseId,
        expireonUser: {
          firstName,
          lastName,
          email,
          userName
        },
        exportJobType: this.props.exportJobType
      })
    );
  };

  render() {
    return (
      <>
        <ExportJobForm
          legalCaseId={this.props.legalCaseId}
          legalHolds={this.props.legalHolds}
          contentSearches={this.props.contentSearches}
          exportPolicies={this.props.exportPolicies}
          onSubmit={this.doSubmit}
          history={this.props.history}
          record={Object.assign({}, { messages: this.props.messages })}
          exportNamesList={this.props.exportNamesList}
          saveLoading={this.props.saveLoading}
          currentTheme={this.props.currentTheme}
          exportJobType={this.props.exportJobType}
        />
      </>
    );
  }
}
function mapStateToProps(store) {
  return {
    legalHolds: store.Export.form.legalHolds,
    contentSearches: store.Export.form.contentSearches,
    exportPolicies: store.Export.form.exportPolicies,
    currentUser: store.auth.currentUser,
    saveLoading: store.Export.form.saveLoading,
    exportNamesList: store.Export.form.exportNamesList,
    currentTheme: store.layout.currentTheme
  };
}

export default withRouter(connect(mapStateToProps)(ExportJobFormPage));
