import React, { Component } from 'react';
import ExportJobFields from '../ExportJobFields';
import { Formik, ErrorMessage } from 'formik';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import Loader from 'components/Loader/Loader';
import CustomSelectFormItem from 'components/FormItems/customSelect/CustomSelectFormItem';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Nav,
  NavLink,
  NavItem,
  Progress
} from 'reactstrap';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { FormattingService } from '../../../../utils/sizeFormatting';
import * as Yup from 'yup';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import styles from 'components/CRUD/Export/Export.module.scss';
import classnames from 'classnames';
import IniValues from 'components/FormItems/iniValues';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
const count = 2;
class ExportJobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportPolicies: [],
      legalHolds: [],
      contentSearches: [],
      openModal: false,
      totalSize: null,
      totalItems: null,
      legalHoldsToExport: 0,
      contentSearchesToExport: 0,
      exportList: [],
      currentStep: 1,
      progress: 50,
      isLastStep: false,
      allowedToExport: false,
      isPerCustodian: false,
      openErrorModal: false,
      selectedLegalHolds: [],
      selectedContentSearches: [],
      holdsWithoutCustodians: [],
      searchesWithoutCustodians: [],
      policyFreeSpace: 0,
      totalSizeInBytes: 0,
      hasSpaceAvailable: true,
      exportPolicyName: '',
      emptySpacePolicies: []
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  nextStep(values) {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
    const selectedExportPolicy = this.state.exportPolicies?.find(
      (item) => item.id === values.exportPolicyId
    );
    this.setState({
      isPerCustodian: selectedExportPolicy?.isPerCustodian,
      policyFreeSpace: selectedExportPolicy?.freeSpaceinBytes,
      exportPolicyName: selectedExportPolicy?.name
    });
  }

  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.legalHolds !== this.props.legalHolds ||
      prevProps.contentSearches !== this.props.contentSearches ||
      prevProps.exportNamesList !== this.props.exportNamesList
    ) {
      this.setState({
        legalHolds: this.props.legalHolds,
        contentSearches: this.props.contentSearches,
        exportList: this.props.exportNamesList
      });
    }
    if (prevProps.exportPolicies !== this.props.exportPolicies) {
      let validExports = [];
      const emptySpacePolicies = [];
      this.props?.exportPolicies?.map((item) => {
        if (item?.isValidLocation) {
          const totalSpace = item?.freeSpaceinBytes + item?.usedSpaceinBytes;
          const newItem = { ...item, totalSpaceinBytes: totalSpace };
          if (newItem?.totalSpaceinBytes === 0) {
            emptySpacePolicies.push(newItem?.id);
          }
          validExports.push(newItem);
        }
      });
      let sortedData = [...validExports].sort((a, b) => b.totalSpaceinBytes - a.totalSpaceinBytes);
      this.setState({
        exportPolicies: sortedData,
        emptySpacePolicies
      });
    }
  }

  initialValues = () => {
    const record = {
      contentSearchIds: [],
      legalHoldIds: []
    };
    return IniValues(ExportJobFields, Object.assign({}, record));
  };

  FormValidationSchema = [
    Yup.object().shape({
      name: Yup.string().trim().required('Name* is a required field'),
      exportPolicyId: Yup.string().required('Export Policy* is a required field')
    }),
    Yup.object().shape({
      contentSearchIds: Yup.array().test(
        'selected-search-to-export',
        'Content Searches* is a required field',
        function (value) {
          if (
            this.parent.contentSearchIds?.length === 0 &&
            this.parent.legalHoldIds?.length === 0
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
      legalHoldIds: Yup.array().test(
        'selected-holds-to-export',
        'Legal Holds* is a required field',
        function (value) {
          if (
            this.parent.contentSearchIds?.length === 0 &&
            this.parent.legalHoldIds?.length === 0
          ) {
            return false;
          } else {
            return true;
          }
        }
      )
    })
  ];

  policyToData = (data) => {
    const arr = [];
    data.map((item) => {
      const obj = {};
      obj.value = item['id'];
      obj.label = item['name'];
      arr.push(obj);
    });
    return arr;
  };

  legalHoldToData = (data) => {
    const toReadableSizeFormat = new FormattingService();
    const arr = [];
    data.map((item) => {
      const obj = {};
      let count = toReadableSizeFormat.numberWithCommas(item?.itemCount);
      obj.value = item['legalHoldId'];
      obj.label = `${item['legalHoldName']} * Items to Export : ${count}`;
      arr.push(obj);
    });
    return arr;
  };

  contentSearchToData = (data) => {
    const toReadableSizeFormat = new FormattingService();
    const arr = [];
    data.map((item) => {
      const obj = {};
      let count = toReadableSizeFormat.numberWithCommas(item['totalItems']);
      obj.value = item['contentSearchId'];
      obj.label = `${item['name']} * Items to Export :${count}`;
      arr.push(obj);
    });
    return arr;
  };

  closeModal = () => {
    this.setState({
      openModal: false,
      allowedToExport: false
    });
  };

  getValues = (values) => {
    const legalHoldList = values.legalHoldIds;
    const contentSearchList = values.contentSearchIds;
    this.setState({
      legalHoldsToExport: legalHoldList?.length,
      contentSearchesToExport: contentSearchList?.length
    });
    const newLegalHoldArray = [];
    legalHoldList?.map((item) => {
      const found = this.state.legalHolds.find((element) => element['legalHoldId'] === item);
      if (found) {
        newLegalHoldArray.push(found);
      }
    });
    this.setState({
      selectedLegalHolds: newLegalHoldArray
    });
    const holdsWithoutCustodians = [];
    if (newLegalHoldArray) {
      newLegalHoldArray?.map((item) => {
        if (item?.custodians?.length === 0 && item?.externalCustodians?.length === 0) {
          holdsWithoutCustodians.push(item);
        }
      });
    }
    this.setState({
      holdsWithoutCustodians
    });
    const newContentSearchArray = [];
    contentSearchList?.map((item) => {
      const found = this.state.contentSearches.find(
        (element) => element['contentSearchId'] === item
      );
      if (found) {
        newContentSearchArray.push(found);
      }
    });
    this.setState({
      selectedContentSearches: newContentSearchArray
    });
    const searchesWithoutCustodians = [];
    if (newContentSearchArray) {
      let foundInvalid = false;
      newContentSearchArray?.map((item) => {
        if (item?.custodians.length !== 0) {
          item?.custodians?.map((data) => {
            if (data?.custodians?.length === 0 && data?.externalCustodians?.length === 0) {
              foundInvalid = true;
            }
          });
          if (foundInvalid) {
            searchesWithoutCustodians.push(item);
          }
        }
        foundInvalid = false;
      });
    }
    this.setState({
      searchesWithoutCustodians
    });

    let totalCount = 0;
    let totalSize = 0;
    newLegalHoldArray.map((item) => {
      const currentCount = item['itemCount'];
      const currentSize = item['itemSizeInBytes'];
      totalCount = totalCount + currentCount;
      totalSize = totalSize + currentSize;
    });
    newContentSearchArray.map((item) => {
      const currentCount = item['totalItems'];
      const currentSize = item['totalSize'];
      totalCount = totalCount + currentCount;
      totalSize = totalSize + currentSize;
    });
    const toReadableSizeFormat = new FormattingService();
    this.setState({
      totalSize: dataFormat.sizeFormatter(totalSize),
      totalItems: toReadableSizeFormat.numberWithCommas(totalCount),
      totalSizeInBytes: totalSize
    });
  };

  handleSubmit = async (values, actions) => {
    if (this.state.isLastStep === true) {
      this.props.onSubmit(values);
    }
    if (this.state.currentStep === count && !this.state.isLastStep) {
      await this.getValues(values);

      if (
        (this.state.holdsWithoutCustodians?.length !== 0 ||
          this.state.searchesWithoutCustodians?.length !== 0) &&
        this.state.isPerCustodian === true
      ) {
        this.setState(
          {
            allowedToExport: false
          },
          () => {
            this.setState({
              openErrorModal: true
            });
          }
        );
      } else {
        this.setState(
          {
            allowedToExport: true
          },
          () => {
            this.setState({
              openModal: true
            });
          }
        );
      }
      if (this.state.totalSizeInBytes > this.state.policyFreeSpace) {
        this.setState({
          hasSpaceAvailable: false
        });
      } else {
        this.setState({
          hasSpaceAvailable: true
        });
      }
    } else {
      this.nextStep(values);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  validateExportName = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.state.exportList?.forEach((item) => {
      let createdExportName = item?.toLowerCase().trim();
      let newExportName = value?.toLowerCase().trim();
      if (createdExportName === newExportName) error = ' This export name already exists';
    });
    return error;
  };

  firstStep = (form) => (
    <div>
      <div>
        <InputFormItem
          name={'name'}
          schema={ExportJobFields}
          validate={this.validateExportName}
          key={'name'}
        />
      </div>
      <div className='mb-2 mt-2'>
        <SelectFormItem
          name={'exportPolicyId'}
          schema={ExportJobFields}
          mode={'fetch'}
          data={this.policyToData(this.state.exportPolicies)}
          emptySpacePolicies={this.state.emptySpacePolicies}
          isExportPolicy={true}
        />
        <ErrorMessage name='exportPolicyId'>
          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );

  secondStep = (form) => (
    <>
      <div className={styles.customSelectItem}>
        <label className='mb-2'>Legal Holds</label>
        <CustomSelectFormItem
          dataOptions={this.legalHoldToData(this.state.legalHolds)}
          name={'legalHoldIds'}
          placeholder={'Select Legal Holds...'}
          isEmpty={this.state.legalHolds.length === 0 ? true : false}
          disabled={form.values.contentSearchIds?.length !== 0 ? true : false}
        />
        <ErrorMessage name='legalHoldIds'>
          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
        </ErrorMessage>
      </div>
      <div className={styles.customSelectItem}>
        <label className='mb-2'>Content Searches</label>
        <CustomSelectFormItem
          dataOptions={this.contentSearchToData(this.state.contentSearches)}
          name={'contentSearchIds'}
          placeholder={'Select Content Searches ...'}
          isEmpty={this.state.contentSearches.length === 0 ? true : false}
          disabled={form.values.legalHoldIds?.length !== 0 ? true : false}
        />
        <ErrorMessage name='contentSearchIds'>
          {(msg) => <div className={styles.errorMessage}>{msg}</div>}
        </ErrorMessage>
      </div>
    </>
  );

  renderStepContent(currentStep, form) {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);

      case 2:
        return this.secondStep(form);

      default:
        return this.firstStep(form);
    }
  }

  renderForm() {
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;
    return (
      <>
        <div className={s.root}>
          <Row>
            <Col sm={12}>
              <Nav pills justified className={s.wizardNavigation}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      wizardActiveItem: currentStep === 1
                    })}
                  >
                    <small>1.</small>
                    &nbsp; General
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      wizardActiveItem: currentStep === 2
                    })}
                  >
                    <small>2.</small>
                    &nbsp; Scope
                  </NavLink>
                </NavItem>
              </Nav>
              <Progress value={this.state.progress} className='progress-xs-wizard' />
              <div>
                <div className={s.stepBody}>
                  <Formik
                    onSubmit={this.handleSubmit}
                    initialValues={this.initialValues()}
                    validationSchema={this.FormValidationSchema[validateStep]}
                  >
                    {(form) => {
                      return (
                        <form onSubmit={form.handleSubmit}>
                          <div>{this.renderStepContent(this.state.currentStep, form)}</div>
                          <div>
                            <ul className={styles.wizardList}>
                              <li className='previous'>
                                <Button
                                  hidden={currentStep === 1}
                                  color='primary'
                                  onClick={this.previousStep}
                                >
                                  <i className='fa fa-caret-left' />
                                  &nbsp;Previous
                                </Button>
                              </li>
                              {currentStep < count && (
                                <li className='next'>
                                  <Button
                                    color='primary'
                                    type='submit'
                                    onClick={() => {
                                      form.handleSubmit;
                                    }}
                                  >
                                    Next <i className='fa fa-caret-right' />
                                  </Button>
                                </li>
                              )}
                              {currentStep === count && (
                                <li className='finish'>
                                  <Button
                                    color='primary'
                                    onClick={() => {
                                      form.handleSubmit();
                                    }}
                                  >
                                    Export <i className='fa fa-check' />
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </div>

                          <Modal
                            size='md'
                            isOpen={this.state.openModal}
                            className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
                          >
                            <ModalHeader
                              className={styles.modalHeader}
                              close={customCloseBtn(() => this.closeModal())}
                            >
                              <div>
                                {!this.state.hasSpaceAvailable ? (
                                  <span className='second-headline-text'>No Space Available</span>
                                ) : (
                                  <span className='second-headline-text'>Export Status</span>
                                )}
                              </div>
                            </ModalHeader>
                            <ModalBody>
                              {!this.state.hasSpaceAvailable ? (
                                <div>
                                  <span>There is no free space for the selected</span>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      padding: '0px 5px'
                                    }}
                                  >
                                    {`${this.state.exportPolicyName}`}
                                  </span>
                                  <span>export policy!</span>
                                </div>
                              ) : (
                                <div>
                                  <div>{`${this.state.legalHoldsToExport} Legal Holds and ${this.state.contentSearchesToExport}  Content Searches selected for export.`}</div>
                                  <div>{`In total ${this.state.totalItems} Items and Final Size is ${this.state.totalSize} .`}</div>
                                </div>
                              )}
                            </ModalBody>
                            {this.state.hasSpaceAvailable && (
                              <div className='d-flex justify-content-center align-content-center mb-3'>
                                <button
                                  type='button'
                                  className='btn secondary-btn me-3'
                                  onClick={() => {
                                    this.closeModal();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type='button'
                                  onClick={() => {
                                    form.handleSubmit();
                                    this.setState({
                                      isLastStep: true,
                                      openModal: false
                                    });
                                  }}
                                  disabled={this.props.saveLoading}
                                  className='btn'
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </Modal>
                          <Modal
                            size='md'
                            isOpen={this.state.openErrorModal}
                            className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
                          >
                            <ModalHeader
                              className={styles.modalHeader}
                              close={customCloseBtn(() =>
                                this.setState({
                                  openErrorModal: false,
                                  allowedToExport: false
                                })
                              )}
                            ></ModalHeader>
                            <ModalBody>
                              <div>
                                <div className={styles.popupHeader}>Unable to create export!</div>
                                <div>
                                  {` Combination of non-custodian ${
                                    this.state.selectedLegalHolds?.length !== 0
                                      ? 'legal holds'
                                      : 'content searches'
                                  } and
                                  export policy per-custodian is not allowed.`}
                                </div>
                                <div className={styles.popupTableContainer}>
                                  <ToolkitProvider
                                    bootstrap4
                                    columns={[
                                      {
                                        dataField: `${
                                          this.state.selectedLegalHolds?.length !== 0
                                            ? 'legalHoldName'
                                            : 'name'
                                        }`,
                                        text: `${
                                          this.state.selectedLegalHolds?.length !== 0
                                            ? 'Legal Holds'
                                            : 'Content Searches'
                                        }`
                                      }
                                    ]}
                                    data={
                                      this.state.selectedLegalHolds?.length !== 0
                                        ? this.state?.holdsWithoutCustodians
                                        : this.state?.searchesWithoutCustodians
                                    }
                                    keyField={`${
                                      this.state.selectedLegalHolds?.length !== 0
                                        ? 'legalHoldId'
                                        : 'contentSearchId'
                                    }`}
                                  >
                                    {(props) => (
                                      <>
                                        <BootstrapTable
                                          bordered={true}
                                          classes={`table-striped table-hover mt-4 fs-sm ${styles.popupTable} `}
                                          noDataIndication={emptyDataMessage}
                                          {...props.baseProps}
                                        />
                                      </>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  render() {
    const { isEditing, findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default ExportJobForm;
