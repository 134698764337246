import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import backIcon from 'images/icons/arrow-left.svg';
import actions from 'actions/OneDrive/OneDrivePreviewActions';
import { fileIcons } from 'actions/common';
import formActions from 'actions/OneDrive/OneDriveFormActions';
import Pagination from 'react-js-pagination';
import paginationStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { Col, Row, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import caretDown from 'images/icons/caret-down.svg';
import { Tree } from 'primereact/tree';
import 'components/CRUD/Search/view/TreeView.css';
import { LuFolder } from 'react-icons/lu';
import { LuFolderOpen } from 'react-icons/lu';
import { RxCaretRight } from 'react-icons/rx';
import { RxCaretDown } from 'react-icons/rx';
import OneDriveFilePreview from './OneDriveFilePreview';
import cx from 'classnames';
import styles from './OneDrivePreview.module.scss';

class OneDrivePreviewPage extends Component {
  state = {
    pageSize: 20,
    pageNumber: 1,
    paginationModalOpen: false,
    totalCount: 0,
    selectedNodeKey: '',
    selectedNodePath: '',
    selectedNode: '',
    filesList: [],
    widget: [],
    showFilePreview: false,
    previewDetails: {}
  };

  componentDidMount() {
    const { dispatch, params } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const legalHoldId = params?.id;
    dispatch(actions.doGetDirectoriesHierarchy(legalHoldId));
    dispatch(formActions.doFind(legalHoldId, expireonUser));
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.legalHoldRecord !== this.props.legalHoldRecord) {
      this.setState({
        pageNumber: 1
      });
    }
    if (prevProps.filesList !== this.props.filesList) {
      this.setState({
        filesList: this.props.filesList,
        totalCount: this.props.totalCount
      });
    }
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize
    ) {
      const legalHoldId = this.props?.params?.id;
      dispatch(
        actions.doGetFilesList(
          this.state.selectedNodePath,
          legalHoldId,
          this.state.selectedNode,
          this.state.pageSize,
          this.state.pageNumber
        )
      ).then(() => {
        this.setState({
          filesList: this.props.filesList,
          totalCount: this.props.totalCount
        });
      });
    }
    if (prevProps.fileDetails !== this.props.fileDetails) {
      this.setState({
        previewDetails: this.props.fileDetails
      });
    }
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  nodeTemplate = (node, options) => {
    return (
      <span
        className={
          this.state.selectedNodeKey === node.key ? `${styles.nodeSelect}` : `${styles.nodeHover}`
        }
      >
        {node.name}
      </span>
    );
  };

  togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    const hasChildren = node.children?.length !== 0;
    const level = node?.key?.split('-')?.length;
    const marginLeft = `${level * 15}px`;
    return (
      <span onClick={options.onClick}>
        {!expanded && hasChildren ? (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span>
              <RxCaretRight className='folder-icon' />
            </span>
            <span className='ms-2'>
              <LuFolder />
            </span>
          </p>
        ) : expanded && hasChildren ? (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span>
              <RxCaretDown className='folder-icon' />
            </span>
            <span className='ms-2'>
              <LuFolderOpen />
            </span>
          </p>
        ) : (
          <p style={{ marginLeft, marginRight: '5px', display: 'flex', marginBottom: '0px' }}>
            <span className='ms-3'></span>
            <span className='ms-2'>
              <LuFolder />
            </span>
          </p>
        )}
      </span>
    );
  };

  findNodePath = (nodeKey, nodes, path = '') => {
    for (let node of nodes) {
      const currentPath = path ? `${path}/${node.name}` : node.name;
      if (node.key === nodeKey) {
        return currentPath;
      }
      if (node.children && node.children.length > 0) {
        const childPath = this.findNodePath(nodeKey, node.children, currentPath);
        if (childPath) {
          return childPath;
        }
      }
    }

    return null; // Return null if node is not found
  };

  handleNodeSelect = (e) => {
    const nodeKey = e.node.key;
    const path = this.findNodePath(nodeKey, this.props?.directoriesList);
    const legalHoldId = this.props?.params?.id;
    const folderName = e.node.name;
    this.setState(
      {
        selectedNodePath: path,
        selectedNode: folderName,
        showFilePreview: false,
        widget: []
      },
      () => {
        this.props.dispatch(actions.doGetFilesList(path, legalHoldId, folderName, 20, 1));
      }
    );
  };

  handleFilePreview = (file, index) => {
    const { dispatch } = this.props;
    this.setState(
      {
        widget: [index],
        showFilePreview: true
      },
      () => {
        dispatch(actions.doGetFilePreview(file));
      }
    );
  };

  render() {
    const { navigate } = this.props;
    return (
      <div>
        <Widget>
          <div className='d-flex justify-content-between align-items-center'>
            <div className={'d-flex mb-4 align-items-center'}>
              <button className={'btn bg-transparent me-2 ps-0'} onClick={() => navigate(-1)}>
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h4 className={`mb-1 first-headline-text d-flex align-items-center mt-1`}>Back</h4>
            </div>
          </div>
          <Row sm={12} style={{ height: '100vh' }}>
            <Col sm={3}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3 fw-bold'>Directories</h3>
                <div className='tree-container'>
                  <Tree
                    value={this.props?.directoriesList}
                    nodeTemplate={this.nodeTemplate}
                    togglerTemplate={this.togglerTemplate}
                    className='p-tree-container'
                    selectionMode='single'
                    selectionKeys={this.state.selectedNodeKey}
                    onSelectionChange={(e) =>
                      this.setState({
                        selectedNodeKey: e.value
                      })
                    }
                    onSelect={this.handleNodeSelect}
                  />
                </div>
              </Widget>
            </Col>
            <Col sm={4}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3 fw-bold'>Files List</h3>
                <div className='ms-3 files-list'>
                  {this.state?.filesList?.map((item, index) => {
                    const filetype = item?.fileName?.split('.').pop();
                    const nodeStyle = {
                      cursor: 'pointer',
                      padding: '5px 5px',
                      fontSize: '15px',
                      fontWeight: `${this.state.widget.includes(index) ? 'bold' : ''}`
                    };
                    return (
                      <p
                        key={index}
                        onClick={() => this.handleFilePreview(item, index)}
                        className={cx({
                          [styles.activeWidget]: this.state.widget.includes(index)
                        })}
                        style={nodeStyle}
                      >
                        <span>{fileIcons(filetype)}</span>
                        <span>{item?.fileName}</span>
                      </p>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px'
                  }}
                >
                  <div className='total-number'>Total: {this.state.totalCount}</div>
                  <div style={{ marginRight: '5px' }}>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={paginationStyles.linkNext}
                        linkClassPrev={paginationStyles.linkNext}
                        linkClassNext={paginationStyles.linkNext}
                        linkClassLast={paginationStyles.linkNext}
                        linkClass={paginationStyles.linkClass}
                        activeLinkClass={paginationStyles.activeLinkClass}
                        activeClass={paginationStyles.activeLinkClass}
                      />
                    ) : null}
                  </div>
                  <Dropdown
                    isOpen={this.state.paginationModalOpen}
                    toggle={() =>
                      this.setState({
                        paginationModalOpen: !this.state.paginationModalOpen
                      })
                    }
                    className={paginationStyles.dropdownClass}
                  >
                    <DropdownToggle className='bg-transparent text-white d-flex'>
                      <p
                        style={{ marginBottom: '3px', marginRight: '2px' }}
                        className='first-body-text'
                      >
                        {this.state.pageSize}
                      </p>
                      <img
                        src={caretDown}
                        alt={'uncollapsed'}
                        width='20px'
                        height='20px'
                        className='ms-2'
                      />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-position-fixed'>
                      {[20, 25, 30].map((item) => (
                        <DropdownItem
                          className={paginationStyles.dropdownItem}
                          key={item}
                          onClick={() => {
                            this.setState({
                              pageSize: item,
                              paginationModalOpen: false,
                              pageNumber: 1
                            });
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Widget>
            </Col>
            <Col sm={5}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center fw-bold'>Preview</h3>
                {this.state.showFilePreview ? (
                  <div>
                    <OneDriveFilePreview fileDetails={this.state.previewDetails} />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      fontSize: '20px',
                      marginTop: '30%',
                      fontWeight: 'bold'
                    }}
                  >
                    <p>No data to display</p>
                    <p>Select a file to see preview</p>
                  </div>
                )}
              </Widget>
            </Col>
          </Row>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    directoriesList: store.OneDrive.list.directoriesList,
    filesList: store.OneDrive.list.filesList,
    totalCount: store.OneDrive.list.totalCount,
    currentUser: store.auth.currentUser,
    legalHoldRecord: store.OneDrive.form.legalHoldRecord,
    fileDetails: store.OneDrive.list.fileDetails
  };
}

export default withRouter(connect(mapStateToProps)(OneDrivePreviewPage));
