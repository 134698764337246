import React, { Component } from 'react';
import ExportListTable from './ExportListTable';
import { connect } from 'react-redux';
import ExportTilesView from './ExportTilesView';
import { withRouter } from 'utils/withRouter';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import s from 'components/CRUD/Search/itemContent/styles.module.scss';
import Widget from 'components/Widget/Widget';
import OneDriveExportTable from './OneDriveExportTable';
import OneDriveExportTiles from './OneDriveExportTiles';
import ChatExportTable from './ChatExportTable';
import ChatExportTiles from './ChatExportTiles';

class ExportListPage extends Component {
  state = {
    defaultTabsTabId: '1'
  };

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }

  render() {
    return (
      <div>
        <Widget style={{ marginTop: '20px' }}>
          <Row>
            <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
              <div className={'d-flex align-items-center'}>
                <h3 className={'first-headline-text me-4 mb-0'}>Export Jobs</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Nav tabs className='bg-transparent d-flex mt'>
              {this.props.permissions['LegalUI.Export'] && (
                <NavItem
                  className={this.state.defaultTabsTabId === '1' ? s.navItem : s.linkText}
                  style={{ marginLeft: '8px' }}
                >
                  <NavLink
                    className={this.state.defaultTabsTabId === '1' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '1');
                    }}
                  >
                    Mail
                  </NavLink>
                </NavItem>
              )}
              {this.props.permissions['LegalUI.Chat.Export'] && (
                <NavItem className={this.state.defaultTabsTabId === '2' ? s.navItem : s.linkText}>
                  <NavLink
                    className={this.state.defaultTabsTabId === '2' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '2');
                    }}
                  >
                    Chat
                  </NavLink>
                </NavItem>
              )}
              {this.props.permissions['LegalUI.File.Export'] && (
                <NavItem className={this.state.defaultTabsTabId === '3' ? s.navItem : s.linkText}>
                  <NavLink
                    className={this.state.defaultTabsTabId === '3' ? s.navLink : s.linkText}
                    onClick={() => {
                      this.changeTab('defaultTabsTabId', '3');
                    }}
                  >
                    File
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent className='mb-xlg' activeTab={this.state.defaultTabsTabId}>
              {this.state.defaultTabsTabId === '1' && (
                <TabPane className={s.tabPane} tabId='1'>
                  <>
                    {this.props.showTableView ? (
                      <ExportListTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    ) : (
                      <ExportTilesView
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    )}
                  </>
                </TabPane>
              )}
              {this.state.defaultTabsTabId === '2' && (
                <TabPane className={s.tabPane} tabId='2'>
                  <>
                    {this.props.showTableView ? (
                      <ChatExportTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    ) : (
                      <ChatExportTiles
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    )}
                  </>
                </TabPane>
              )}
              {this.state.defaultTabsTabId === '3' && (
                <TabPane className={s.tabPane} tabId='3'>
                  <>
                    {this.props.showTableView ? (
                      <OneDriveExportTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    ) : (
                      <OneDriveExportTiles
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    )}
                  </>
                </TabPane>
              )}
            </TabContent>
          </Row>
        </Widget>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    showTableView: store.Export.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(ExportListPage));
