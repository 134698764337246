import React, { Component } from 'react';
import {
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody
} from 'reactstrap';
import s from './Wizard.module.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { emptyDataMessage } from 'actions/common';
import Pagination from 'react-js-pagination';
import styles from '../list/LegalHoldList.module.scss';
import formActions from 'actions/LegalHold/LegalHoldFormActions';
import { connect } from 'react-redux';
import { ImInfo } from 'react-icons/im';
import config from 'config';
import LegalHoldTooltip from './LegalHoldTooltip';
import UserIdentitiesTooltip from './UserIdentitiesTooltip';
import TemporaryCustodiansTooltip from './TemporaryCustodiansTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import LongNameTooltip from './LongNameTooltip';
import { defaultOptions } from '../../../../services/userService';
import caretDown from 'images/icons/caret-down.svg';

class CustodianSelectionList extends Component {
  state = {
    totalCount: 0,
    paginationModalOpen: false,
    pageNumber: 1,
    pageSize: 5,
    newRows: [],
    selectedCustodians: [],
    currentIdentities: [],
    searchString: '',
    acceptLoading: false,
    showTooltip: false,
    showIdentitiesTooltip: false,
    position: {
      top: 0,
      left: 0
    },
    person: {},
    identityType: '',
    count: 0,
    openConfirmationModal: false,
    currentCustodianId: '',
    selectedCustodiansCount: 0,
    showTemporaryCustodians: false,
    showNameTooltip: false,
    longName: '',
    namePosition: {
      top: 0,
      left: 0
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize
    ) {
      dispatch(
        formActions.doGetCustodians(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.searchString
        )
      ).then(() => {
        this.setState({
          newRows: this.props.custodians,
          totalCount: this.props.custodiansCount
        });
      });
    }
    if (prevState.person !== this.state.person && this.state.person?.value) {
      this.setState({
        showTooltip: true
      });
    }
    if (prevState.identityType !== this.state.identityType && this.state.identityType !== '') {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (
      prevState.currentIdentities !== this.state.currentIdentities &&
      this.state.currentIdentities.length > 0
    ) {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      this.setState({
        newRows: [],
        totalCount: 0
      });
    }
  }

  rowClasses = (row, rowIndex) => {
    let isSelected = this.isCustodianSelected(row);
    if (!isSelected) {
      return `${s.customRowClass} ${s.customRowCursor}`;
    } else {
      return `${s.customRowCursor}`;
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    if (this.state.searchString !== '') {
      dispatch(
        formActions.doGetCustodians(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.searchString
        )
      ).then(() => {
        this.setState({
          newRows: this.props.custodians,
          totalCount: this.props.custodiansCount
        });
      });
    }
  };

  handleAddSelected = (row) => {
    const { dispatch } = this.props;
    let selected = row;
    const isSelected = this.isCustodianSelected(row);

    if (isSelected) {
      let modifiedArr = this.state?.selectedCustodians?.filter(
        (item) => (item = item.custodianId !== row.custodianId)
      );
      this.setState(
        {
          selectedCustodians: modifiedArr
        },
        () => {
          let uniqueCustodiansArray = this.getUniqueData(this.state.selectedCustodians);
          this.setState({
            selectedCustodiansCount: uniqueCustodiansArray?.length,
            selectedCustodians: uniqueCustodiansArray
          });
          dispatch(formActions.doSetSelectedCustodiansCount(uniqueCustodiansArray?.length));
        }
      );
    } else {
      selected = { ...row, icon: 'check' };
      let updatedArray = this.state.selectedCustodians.concat(selected);
      this.setState(
        {
          selectedCustodians: updatedArray
        },
        () => {
          let uniqueCustodiansArray = this.getUniqueData(this.state.selectedCustodians);
          this.setState({
            selectedCustodiansCount: uniqueCustodiansArray?.length,
            selectedCustodians: uniqueCustodiansArray
          });
          dispatch(formActions.doSetSelectedCustodiansCount(uniqueCustodiansArray?.length));
        }
      );
    }
    const updatedData = this.state.newRows?.map((data) => {
      if (data.custodianId === row.custodianId) {
        if (row?.icon === 'check') {
          return { ...data, icon: 'plus' };
        } else {
          return { ...data, icon: 'check' };
        }
      }
      return data;
    });
    this.setState({
      newRows: updatedData
    });
  };

  getUniqueData = (data) => {
    let uniquData = [];
    uniquData = data?.reduce((accumulator, current) => {
      const isDuplicate = accumulator.some((item) => item.custodianId === current.custodianId);
      if (!isDuplicate) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return uniquData;
  };

  handleAcceptSelected = () => {
    const { dispatch } = this.props;
    let uniqueCustodiansArray = this.getUniqueData(this.state.selectedCustodians);
    this.setState(
      {
        acceptLoading: true
      },
      async () => {
        let updatedArray = this.props.selectedCustodiansList?.concat(uniqueCustodiansArray);
        await dispatch(formActions.doSetSelectedCustodians(updatedArray)).then(() => {
          this.setState(
            {
              acceptLoading: false,
              selectedCustodians: []
            },
            () => {
              dispatch(formActions.doSetSelectedCustodiansCount(0));
              dispatch(formActions.doCloseInternalSelection());
            }
          );
        });
      }
    );
    this.props.setFieldTouched('custodians', true);
    this.props.setFieldValue('custodians', uniqueCustodiansArray);
  };

  handleDiscardSelected = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        selectedCustodians: []
      },
      () => {
        dispatch(formActions.doSetSelectedCustodiansCount(0));
        dispatch(formActions.doCloseInternalSelection());
      }
    );
  };

  handleUserIdentities = (custodianId, identityType) => {
    const { dispatch } = this.props;
    dispatch(formActions.doGetCustodianIdentities(custodianId)).then(() => {
      let newList = [];
      this.props.userIdentities?.map((item) => {
        if (item?.identityType === identityType) {
          newList = item?.value;
        }
      });
      this.setState({
        currentIdentities: newList
      });
    });
  };

  isCustodianSelected = (row) => {
    let isSelected = false;
    let allSelectedCustodians = this.props?.selectedCustodiansList?.concat(
      this.state?.selectedCustodians
    );
    const found = allSelectedCustodians.find((item) => item.custodianId === row.custodianId);
    if (found) {
      isSelected = true;
    }
    return isSelected;
  };

  handleGetCustodianDetails = (id) => {
    const url = `${config.externalApi}/legal/api/Custodian/GetCustodianById?custodianId=${id}`;

    fetch(url, {
      ...defaultOptions
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((details) => {
        this.setState({
          person: details
        });
      })
      .then(() => {
        this.setState({
          showTooltip: true
        });
      })
      .catch((error) => {
        console.error('Error fetching custodian details:', error);
      });
  };

  handleOpenConfirmationModal = () => {
    const { dispatch } = this.props;
    this.handleAcceptSelected();
    dispatch(formActions.doCloseInternalSelection());
  };

  handleCloseConfirmationModal = () => {
    const { dispatch } = this.props;
    this.setState({
      selectedCustodians: []
    });
    dispatch(formActions.doCloseInternalSelection());
  };

  render() {
    const columns = [
      {
        dataField: 'displayName',
        text: 'Display Name',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Department</p>
            </div>
          );
        },
        formatter: (cell, row) => {
          return (
            <div className='w-100 d-flex align-items-center'>
              <span
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  this.setState({
                    position: {
                      top: e.pageY,
                      left: e.pageX
                    }
                  });
                }}
              >
                <ImInfo
                  onClick={(e) => {
                    this.handleGetCustodianDetails(row?.custodianId);
                    this.setState({
                      showTooltip: true,
                      showIdentitiesTooltip: false,
                      showNameTooltip: false
                    });
                  }}
                  style={{
                    marginRight: '8px',
                    width: '16px',
                    height: '16px',
                    color: '#2d8515'
                  }}
                />
              </span>

              <div>
                {row?.displayName && (
                  <p
                    className={`mb-0 fw-semi-bold me-1`}
                    onMouseOver={(e) => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.displayName?.length <= 15
                      ? row?.displayName
                      : `${row?.displayName?.slice(0, 15)}...`}
                  </p>
                )}

                {row?.department && (
                  <p
                    className='mb-0 me-1'
                    onMouseOver={(e) => {
                      if (row?.department?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.department,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.department?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.department?.length <= 15
                      ? row?.department
                      : `${row?.department?.slice(0, 15)}...`}
                  </p>
                )}
              </div>
            </div>
          );
        }
      },
      {
        dataField: 'smtpAddress',
        text: 'Email Address',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Sam Account</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <div className='d-flex justify-content-between'>
            <div>
              {row?.smtpAddress && (
                <div>
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (row?.smtpAddress?.length > 30) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.smtpAddress,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.smtpAddress?.length > 30) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.smtpAddress?.length <= 30
                      ? row?.smtpAddress
                      : `${row?.smtpAddress?.slice(0, 30)}...`}
                  </p>
                </div>
              )}
              {row?.samAccountName && (
                <p
                  className='mb-0'
                  onMouseOver={(e) => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.samAccountName,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.samAccountName?.length <= 30
                    ? row?.samAccountName
                    : `${row?.samAccountName?.slice(0, 30)}...`}
                </p>
              )}
            </div>

            <div>
              {row?.custodianIdentitiesCount?.map((item) => {
                return (
                  <>
                    <p className='d-flex justify-content-between'>
                      <span className='me-2'>{item.count}</span>
                      <span
                        className={`${
                          item.identityType === 'SMTP' ? s.identitySmtpBox : s.identityX500Box
                        }`}
                        onClick={(e) => {
                          this.handleUserIdentities(row?.custodianId, item?.identityType);
                          this.setState({
                            showIdentitiesTooltip: true,
                            position: {
                              top: e.pageY,
                              left: e.pageX
                            },
                            identityType: item?.identityType,
                            showTooltip: false,
                            showIdentitiesTooltip: false,
                            count: item?.count
                          });
                        }}
                      >
                        {item.identityType}
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        )
      },
      {
        dataField: 'upn',
        text: 'Source',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>User principal name</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <>
            <p className={`mb-0 fw-semi-bold`}>{row.hasAdRecord ? 'AD' : 'N/A'}</p>
            {row?.upn && (
              <div className='w-100'>
                <p
                  className='mb-0'
                  onMouseOver={(e) => {
                    if (row?.upn?.length > 25) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.upn,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.upn?.length > 25) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.upn?.length <= 25 ? row?.upn : `${row?.upn?.slice(0, 25)}...`}
                </p>
              </div>
            )}
          </>
        )
      },
      {
        dataField: 'custodianId',
        text: 'Actions',
        formatter: (cell, row) => {
          const isSelected = this.isCustodianSelected(row);
          if (isSelected) {
            return (
              <p className={s.icon} onClick={() => this.handleAddSelected(row)}>
                <FontAwesomeIcon icon={faCheck} className='w-100' />
              </p>
            );
          } else {
            return (
              <p className={s.icon} onClick={() => this.handleAddSelected(row)}>
                {<FontAwesomeIcon icon={row?.icon === 'check' ? faCheck : faPlus} />}
              </p>
            );
          }
        }
      }
    ];

    return (
      <div
        onClick={() => {
          this.setState({
            showTooltip: false,
            person: {}
          });
          if (this.state.showIdentitiesTooltip) {
            this.setState({
              showIdentitiesTooltip: false,
              currentIdentities: []
            });
          }
          if (this.state.showTemporaryCustodians) {
            this.setState({
              showTemporaryCustodians: false
            });
          }
          if (this.state.showNameTooltip) {
            this.setState({
              showNameTooltip: false
            });
          }
        }}
      >
        <div className='d-flex w-100'>
          <span className='w-100'>
            <input
              ref={this.props.inputRef}
              type='search'
              placeholder={'Search'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  this.handleSearch();
                }
              }}
              style={{
                border: '0.5px solid #0046b1',
                borderRadius: '3px'
              }}
              className={'form-control search-input me-5 '}
              value={this.state.searchString}
              onChange={(e) => {
                this.setState({
                  searchString: e.target.value
                });
              }}
            />
          </span>
          <span>
            <button
              className='btn search-button-with-icon me-2 ms-10 first-body-text'
              type='button'
              onClick={() => this.handleSearch()}
              style={{ height: '37px', width: 'auto' }}
            >
              Search
            </button>
          </span>
        </div>
        {this.state.showTooltip && (
          <LegalHoldTooltip currentCustodian={this.state.person} position={this.state.position} />
        )}
        {this.state.showIdentitiesTooltip && (
          <UserIdentitiesTooltip
            identitiesList={this.state.currentIdentities}
            position={this.state.position}
            identityType={this.state.identityType}
            count={this.state.count}
          />
        )}
        {this.state.showTemporaryCustodians && (
          <TemporaryCustodiansTooltip
            selectedCustodians={this.state.selectedCustodians}
            position={this.state.position}
          />
        )}
        {this.state.showNameTooltip && (
          <LongNameTooltip longName={this.state.longName} position={this.state.namePosition} />
        )}
        <div className='h-100'>
          <ToolkitProvider
            bootstrap4
            keyField='custodianId'
            columns={columns}
            data={this.state.newRows}
          >
            {(props) => (
              <>
                <div>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover mt-4 fs-sm ${s.internalCustodiansTable}`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    noDataIndication={emptyDataMessage}
                    rowClasses={this.rowClasses}
                    {...props.baseProps}
                  />
                </div>
                <Row>
                  <Col className='d-flex justify-content-between mt-5 w-100'>
                    {this.state.totalCount !== 0 ? (
                      <p className='me-1 mt-1'>Results: {this.state.totalCount}</p>
                    ) : null}
                    <div className='w-60 '>
                      {this.state.totalCount ? (
                        <div className='d-flex'>
                          <Pagination
                            totalItemsCount={this.state.totalCount}
                            onChange={this.handlePageChange}
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.state.pageSize}
                            pageRangeDisplayed={5}
                            prevPageText={'<'}
                            nextPageText={'>'}
                            firstPageText={'<<'}
                            lastPageText={'>>'}
                            linkClassFirst={s.pageNext}
                            linkClassPrev={s.pageNext}
                            linkClassNext={s.pageNext}
                            linkClassLast={s.pageNext}
                            linkClass={s.pageLink}
                            activeLinkClass={s.activeLink}
                          />

                          <Dropdown
                            isOpen={this.state.paginationModalOpen}
                            toggle={() =>
                              this.setState({
                                paginationModalOpen: !this.state.paginationModalOpen
                              })
                            }
                            className={s.pageSizeDropdown}
                          >
                            <DropdownToggle className='btn btn-secondary bg-transparent text-white d-flex'>
                              <p
                                style={{
                                  marginBottom: '3px',
                                  marginRight: '2px'
                                }}
                                className='first-body-text'
                              >
                                {this.state.pageSize}
                              </p>
                              <img
                                src={caretDown}
                                alt={'uncollapsed'}
                                width='20px'
                                height='20px'
                                className='ms-2'
                              />
                            </DropdownToggle>
                            <DropdownMenu className='dropdown-position-fixed'>
                              {[5, 10, 25].map((item) => (
                                <DropdownItem
                                  className={s.dropdownItem}
                                  key={item}
                                  onClick={() => {
                                    this.setState({
                                      pageSize: item,
                                      paginationModalOpen: false,
                                      pageNumber: 1
                                    });
                                  }}
                                >
                                  {item}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      ) : null}
                    </div>

                    <div className='w-13 d-flex mt-1'>
                      <span className='me-2'>
                        {`Selected: ${this.state.selectedCustodiansCount}`}
                      </span>
                      <span
                        style={{
                          cursor: this.state.selectedCustodiansCount > 50 ? 'default' : 'pointer'
                        }}
                        onClick={(e) => {
                          this.setState({
                            position: {
                              top: e.pageY,
                              left: e.pageX
                            }
                          });
                        }}
                      >
                        <ImInfo
                          onClick={(e) => {
                            if (this.state.selectedCustodiansCount <= 50) {
                              this.setState({
                                showTemporaryCustodians: true,
                                showTooltip: false,
                                showIdentitiesTooltip: false
                              });
                            }
                          }}
                          style={{
                            marginLeft: '5px',
                            width: '16px',
                            height: '16px',
                            color: this.state.selectedCustodiansCount > 50 ? '#f4f4f580' : 'white'
                          }}
                          className='me-1 mb-1'
                        />
                      </span>
                    </div>

                    <div className=' d-flex w-20'>
                      <button
                        className='btn secondary-btn me-2 first-body-text'
                        type='button'
                        onClick={() => this.handleDiscardSelected()}
                        style={{ height: '37px', width: '70px' }}
                      >
                        Reset
                      </button>
                      <button
                        disabled={this.state.acceptLoading}
                        className='btn ms-2 first-body-text'
                        type='button'
                        onClick={() => this.handleAcceptSelected()}
                        style={{ height: '37px', width: '70px' }}
                      >
                        Accept
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
          {this.state.selectedCustodians.length > 0 && (
            <Modal
              size='sm'
              isOpen={this.props.openCustodianModal}
              className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
            >
              <ModalBody>
                <div className='w-100 mt-5'>
                  <p className='d-flex justify-content-center mb-0'>
                    {`You have selected ${this.state.selectedCustodiansCount} custodians.`}
                  </p>
                  <p className='d-flex justify-content-center'>Add them to the custodian list?</p>
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn secondary-btn first-body-text me-3'
                      type='button'
                      onClick={() => this.handleCloseConfirmationModal()}
                      style={{ height: '37px', width: '45px' }}
                    >
                      No
                    </button>
                    <button
                      className='btn  first-body-text'
                      type='button'
                      onClick={() => this.handleOpenConfirmationModal()}
                      style={{ height: '37px', width: '45px' }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    custodians: store.LegalHold.form.custodians,
    custodiansCount: store.LegalHold.form.custodiansCount,
    userIdentities: store.LegalHold.form.userIdentities,
    selectedCustodiansList: store.LegalHold.form.selectedCustodiansList,
    openCustodianModal: store.LegalHold.form.openCustodianModal,
    currentTheme: store.layout.currentTheme
  };
}
export default connect(mapStateToProps)(CustodianSelectionList);
