import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Nav,
  NavLink,
  NavItem,
  Progress,
  ModalHeader,
  ModalBody,
  Modal,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import DatePickerFormItem from 'components/FormItems/items/DatePickerFormItem';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import LegalHoldFields from 'components/CRUD/LegalHold/LegalHoldFields';
import IniValues from 'components/FormItems/iniValues';
import config from 'config';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import SelectByAddingFormItem from 'components/FormItems/items/SelectByAddingFormItem';
import { ImInfo } from 'react-icons/im';
import formActions from 'actions/OneDrive/OneDriveFormActions';
import { FormattingService } from 'utils/sizeFormatting';
import LegalHoldTooltip from 'components/CRUD/LegalHold/form/LegalHoldTooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import CustodianSelectionList from 'components/CRUD/LegalHold/form/CustodianSelectionList';
import ExternalCustodiansForm from './ExternalCustodiansForm';
import { BiCopyright } from 'react-icons/bi';
import LongNameTooltip from 'components/CRUD/LegalHold/form/LongNameTooltip';
import UserIdentitiesTooltip from 'components/CRUD/LegalHold/form/UserIdentitiesTooltip';
import searchFormStyles from 'components/CRUD/Search/form/SearchForm.module.scss';
import { defaultOptions } from 'services/userService';
import caretDown from 'images/icons/caret-down.svg';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import listActions from 'actions/OneDrive/OneDrivePreviewActions';
import PreparedValues from 'components/FormItems/preparedValues';

const count = 4;
const toReadableSizeFormat = new FormattingService();

const DATE_RANGE_OPTIONS = {
  lAST_7_DAYS: 'Last 7 days',
  lAST_30_DAYS: 'Last 30 days',
  lAST_90_DAYS: 'Last 90 days',
  lAST_1_YEAR: 'Last 1 year',
  lAST_3_YEARS: 'Last 3 years',
  lAST_7_YEARS: 'Last 7 years',
  lAST_10_YEARS: 'Last 10 years'
};

const DATE_RANGE_OPTIONS_MAP = {
  lAST_7_DAYS: 'lAST_7_DAYS',
  lAST_30_DAYS: 'lAST_30_DAYS',
  lAST_90_DAYS: 'lAST_90_DAYS',
  lAST_1_YEAR: 'lAST_1_YEAR',
  lAST_3_YEARS: 'lAST_3_YEARS',
  lAST_7_YEARS: 'lAST_7_YEARS',
  lAST_10_YEARS: 'lAST_10_YEARS'
};

class OneDriveForm extends Component {
  constructor(prop) {
    super(prop);
    this.inputRef = React.createRef();
    this.state = {
      currentStep: 1,
      progress: 25,
      externalCustodiansList: [],
      disableChannels: true,
      position: {
        top: 0,
        left: 0
      },

      showTooltip: false,
      person: {},
      paginationModalOpen: false,
      newRows: [],
      selectedCustodians: [],
      openExternalModal: false,
      externalCustodianInfo: [],
      internalCustodiansList: [],
      currentPage: 1,
      itemsPerPage: 5,
      itemsToDisplay: [],
      totalPages: 0,
      pageNumbers: [],
      showIdentitiesTooltip: false,
      currentIdentities: [],
      identityType: '',
      count: 0,
      longName: '',
      showNameTooltip: false,
      namePosition: {
        top: 0,
        left: 0
      }
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  interval = 0;

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;

    if (prevState.currentStep !== this.state.currentStep) {
      dispatch(formActions.doSetCurrentStep(this.state.currentStep));
    }
    if (prevState.person !== this.state.person && this.state.person?.value) {
      this.setState({
        showTooltip: true
      });
    }
    if (prevState.identityType !== this.state.identityType && this.state.identityType !== '') {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (
      prevState.currentIdentities !== this.state.currentIdentities &&
      this.state.currentIdentities.length > 0
    ) {
      this.setState({
        showIdentitiesTooltip: true
      });
    }

    if (
      prevProps.selectedCustodiansList !== this.props?.selectedCustodiansList ||
      prevProps.selectedExternalCustodians !== this.props?.selectedExternalCustodians
    ) {
      this.setState(
        {
          newRows: this.props?.selectedCustodiansList,
          selectedCustodians: this.props?.selectedCustodiansList,
          externalCustodianInfo: this.props?.selectedExternalCustodians
        },
        () => {
          this.setState({
            externalCustodiansList: this.externalCustodiansFormatter(
              this.props?.selectedExternalCustodians
            ),
            internalCustodiansList: this.getInternalCustodians(this.props.selectedCustodiansList)
          });
        }
      );
    }
    if (
      prevState.newRows !== this.state.newRows ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.currentPage !== this.state.currentPage
    ) {
      const { currentPage, itemsPerPage } = this.state;
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const itemsToDisplay = this.state.newRows?.slice(startIndex, endIndex);
      const totalPages = Math.ceil(this.state.newRows?.length / itemsPerPage);
      const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
      this.setState({
        itemsToDisplay,
        totalPages,
        pageNumbers
      });
    }
    if (
      prevProps.legalHoldStats !== this.props.legalHoldStats &&
      this.props.legalHoldStats?.isResultValid
    ) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(formActions.doSetCurrentStep(1));
    dispatch(formActions.doSetSelectedCustodians([]));
    dispatch(formActions.doSetSelectedCustodiansCount(0));
    clearInterval(this.interval);
  }

  externalCustodiansFormatter(array) {
    let formattedArray = [];
    array?.map((item) => {
      formattedArray.push(item?.emailAddress);
    });
    return formattedArray;
  }

  getInternalCustodians(array) {
    let internalCustodians = [];
    array?.map((item) => {
      if (!item.isExternal) {
        internalCustodians.push(item);
      }
    });
    return internalCustodians;
  }

  nextStep() {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  }

  iniValues = () => {
    const record = {
      custodians: [],
      externalCustodians: []
    };
    return IniValues(LegalHoldFields, Object.assign({}, record));
  };

  toData = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = data[key];
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = key;
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  channelsFormatter = (data) => {
    const arr = [];
    for (let key in data) {
      const obj = {};
      obj.value = data[key];
      obj.label = data[key].channelName;
      arr.push(obj);
    }
    return arr;
  };

  containerFormatter = (data) => {
    const arr = [];
    if (Array.isArray(data)) {
      for (let key of data) {
        const obj = {};
        obj.value = key?.containerId;
        obj.label = `${key?.name}` + `*` + `${key?.description}`;
        arr.push(obj);
      }
    }
    return arr;
  };

  getContainerNames = (containerIds) => {
    let selectedContainers = [];
    this.props?.containers?.map((item) => {
      let selected = containerIds?.find((el) => el === item?.containerId);
      if (selected) {
        selectedContainers.push(item.name);
      }
    });
    return selectedContainers;
  };

  FormValidationSchema = [
    Yup.object().shape({
      legalHoldName: Yup.string().trim().required('Name* is a required field'),
      legalHoldDescription: Yup.string().trim().required('Description* is a required field')
    }),
    Yup.object().shape({
      containerIds: Yup.mixed().required('Containers* is a required field'),
      dateFrom: Yup.mixed().when('dateTo', (dateTo, schema) => {
        return (
          dateTo &&
          schema.test({
            test: function (dateFrom) {
              return Date.parse(dateTo) > Date.parse(dateFrom);
            },
            message: 'Start date must be earlier than end date '
          })
        );
      }),
      dateTo: Yup.mixed().required('End* date is a required field')
    }),
    Yup.object().shape({
      custodians: Yup.array().test(
        'internal-or-external',
        '*Please select at least one custodian, internal or external.',
        function (value) {
          if (
            this.parent.custodians?.length === 0 &&
            this.parent.externalCustodians?.length === 0
          ) {
            return false;
          } else {
            return true;
          }
        }
      )
    })
  ];

  handleSubmit = (values, actions) => {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    let hasError = false;
    const errors = {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };

    const notConfirmed = this.props.isEditing || this.props.isNotConfirmed;
    let { id, ...data } = PreparedValues(LegalHoldFields, values || {}, notConfirmed);
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    if (this.props.isEditing || this.props.isNotConfirmed) {
      data.legalCaseId = this.props.record.legalCaseId;
    }
    let custodiansArr = [];
    if (this.state.internalCustodiansList) {
      this.state.internalCustodiansList?.forEach((item) => {
        custodiansArr.push(item?.custodianId ? item?.custodianId : item?.id);
      });
    }

    const containerNames = this.getContainerNames(values?.containerIds);
    this.setState({
      containerNames: containerNames
    });

    const filters = {
      ...data,
      containerIds: values.containerIds,
      externalCustodians: this.state.externalCustodiansList,
      custodians: custodiansArr,
      legalCaseId: this.props.legalCaseId,
      externalCustodianInfo: this.state.externalCustodianInfo
    };
    if (filters.custodians?.length === 0 && filters.externalCustodianInfo?.length === 0) {
      errors.custodians = '*Please select at least one custodian, internal or external.';
      hasError = true;
    }
    if (this.state.currentStep === 3) {
      if (hasError) {
        actions.setErrors(errors);
      } else {
        this.props
          .dispatch(
            formActions.doCreate({
              ...filters,
              expireonUser: expireonUser
            })
          )
          .then(() => {
            this.props.dispatch(
              listActions.doFetchFilesLH({
                legalCaseId: this.props.legalCaseId,
                searchString: '',
                pageNumber: this.props.pageNumber,
                pageSize: this.props.pageSize,
                sortOrder: ''
              })
            );
            this.interval = setInterval(() => {
              this.props.dispatch(
                formActions.doGetLegalHoldStatistics(this.props?.createdLegalHoldId)
              );
            }, 20000);
          });
        this.nextStep();
      }
    } else {
      this.nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  validateLHName = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.props?.legalHoldNames?.forEach((item) => {
      const createdLegalHold = item?.toLowerCase().trim();
      const newLegalHold = value?.toLowerCase().trim();
      if (createdLegalHold === newLegalHold) error = 'This legal hold name already exists';
    });
    return error;
  };

  handleDateRangeSelect(form) {
    if (form.values.dateRange) {
      form.values.dateTo = new Date();
      form.values.dateFrom = new Date();
      form.values.dateFrom.setHours(0, 0, 0);
      form.values.dateTo.setHours(23, 59, 59, 999);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_7_DAYS) {
      form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 7);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_30_DAYS) {
      form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 30);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_90_DAYS) {
      form.values.dateFrom.setMonth(form.values.dateFrom.getMonth() - 3);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_1_YEAR) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 1);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_3_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 3);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_7_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 7);
    }
    if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_10_YEARS) {
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 10);
    }
  }

  handleStartDateChange = (form) => {
    if (!form.values.dateFrom) {
      form.values.dateFrom = new Date();
      form.values.dateFrom.setHours(0, 0, 0);
      form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 30);
    }
  };

  handleEndDateChange = (form) => {
    if (!form.values.dateTo) {
      form.values.dateTo = new Date();
      form.values.dateTo.setHours(23, 59, 59, 999);
    }
  };

  validateContainer = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    if (value?.length === 0) {
      error = 'Please choose a containers to continue';
    }
    return error;
  };

  openCustodianModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenInternalSelection()).then(() => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  }

  closeCustodianModal() {
    const { dispatch } = this.props;
    if (this.props.temporaryCustodiansCount > 0) {
      dispatch(formActions.doOpenCustodianConfirmation());
    } else {
      dispatch(formActions.doCloseInternalSelection());
    }
  }

  openExternalCustodianModal() {
    this.setState({
      openExternalModal: true
    });
  }

  closeExternalCustodianModal() {
    this.setState({
      openExternalModal: false
    });
  }

  handleRemoveSelected = async (row) => {
    const { dispatch } = this.props;
    const custodianId = row?.custodianId;
    let updatedArray = this.props?.selectedCustodiansList?.filter(
      (item) => item?.custodianId !== custodianId
    );
    await dispatch(formActions.doSetSelectedCustodians(updatedArray));
    if (row?.isExternal) {
      let updatedExternalInfo = this.state.externalCustodianInfo?.filter(
        (item) => item?.emailAddress !== row?.smtpAddress
      );
      let updatedExternalList = this.state.externalCustodiansList?.filter(
        (item) => item !== row?.smtpAddress
      );
      dispatch(formActions.doSetSelectedExternalCustodians(updatedExternalInfo));
      this.setState({
        externalCustodianInfo: updatedExternalInfo,
        externalCustodiansList: updatedExternalList
      });
    }
    if (this.state.itemsToDisplay?.length === 0 && this.state.currentPage > 1) {
      this.setState({
        currentPage: this.state.currentPage - 1
      });
    }
  };

  actionFormatter = (cell, row) => {
    return (
      <div className={s.icon}>
        <span onClick={() => this.handleRemoveSelected(row)}>
          <i
            className='fa fa-trash'
            style={{
              width: '14px',
              height: '14px'
            }}
          />
        </span>
      </div>
    );
  };

  isCustodianSelected = (row) => {
    let isSelected = false;
    const found = this.state.selectedCustodians.find(
      (item) => item.custodianId === row.custodianId
    );
    if (found) {
      isSelected = true;
    }
    return isSelected;
  };

  rowClasses = (row, rowIndex) => {
    let isSelected = this.isCustodianSelected(row);
    if (!isSelected) {
      return `${s.customRowCursor} ${s.customCheckCursor}`;
    } else {
      return `${s.customRowCursor} ${s.customRemoveCursor}`;
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerPageChange = (item) => {
    this.setState({
      itemsPerPage: item,
      currentPage: 1,
      paginationModalOpen: false
    });
  };

  handleUserIdentities = (custodianId, identityType) => {
    const { dispatch } = this.props;
    dispatch(formActions.doGetCustodianIdentities(custodianId)).then(() => {
      let newList = [];
      this.props.userIdentities?.map((item) => {
        if (item?.identityType === identityType) {
          newList = item?.value;
        }
      });
      this.setState({
        currentIdentities: newList
      });
    });
  };

  firstStep = (form) => (
    <div>
      <InputFormItem
        name={'legalHoldName'}
        schema={LegalHoldFields}
        validate={this.validateLHName}
        autoFocus
      />
      <div>
        <TextAreaFormItem
          name={'legalHoldDescription'}
          textAreaStyle={{ height: '250px' }}
          schema={LegalHoldFields}
        />
      </div>
    </div>
  );

  secondStep = (form) => (
    <Row>
      <Col>
        <SelectByAddingFormItem
          name={'containerIds'}
          schema={LegalHoldFields}
          mode={'fetch'}
          isMulti={true}
          key={'containerIds'}
          widgetHeight={'293px'}
          data={this.containerFormatter(this.props.containers)}
          validate={this.validateContainer}
          isContainer={true}
        />
      </Col>
      <Col sm={5} className={s.dateRangeContainer}>
        <Col sm={12} className={'mb-3'}>
          <SelectFormItem
            name={'dateRange'}
            schema={LegalHoldFields}
            placeholder={'Between'}
            onChange={this.handleDateRangeSelect(form)}
            mode={'fetch'}
            isClearable={false}
            data={this.toData(DATE_RANGE_OPTIONS)}
          />
        </Col>
        <Col sm={12} className={'mb-3'}>
          <DatePickerFormItem
            name={'dateFrom'}
            schema={LegalHoldFields}
            showTimeInput
            onChange={this.handleStartDateChange(form)}
          />
          <ErrorMessage name='dateFrom'>
            {(msg) => <div className={s.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </Col>
        <Col sm={12} className={'mb-3'}>
          <DatePickerFormItem
            name={'dateTo'}
            schema={LegalHoldFields}
            showTimeInput
            onChange={this.handleEndDateChange(form)}
          />
          <ErrorMessage name='dateTo'>
            {(msg) => <div className={s.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </Col>
      </Col>
    </Row>
  );

  thirdStep = (form, columns) => (
    <>
      {this.state.showTooltip && (
        <LegalHoldTooltip currentCustodian={this.state.person} position={this.state.position} />
      )}
      {this.state.showIdentitiesTooltip && (
        <UserIdentitiesTooltip
          identitiesList={this.state.currentIdentities}
          position={this.state.position}
          identityType={this.state.identityType}
          count={this.state.count}
        />
      )}
      {this.state.showNameTooltip && (
        <LongNameTooltip longName={this.state.longName} position={this.state.namePosition} />
      )}
      <div className='d-flex justify-content-between align-items-center mt-5 mb-2'>
        <p className='fw-semi-bold h5'> Selected Custodians</p>
        <div>
          <button
            className='btn me-3 first-body-text'
            type='button'
            onClick={() => this.openCustodianModal()}
            style={{ height: '37px', width: 'auto' }}
          >
            Add from directory
          </button>
          <button
            className='btn me-3 first-body-text'
            type='button'
            onClick={() => {
              this.openExternalCustodianModal();
            }}
            style={{ height: '37px', width: 'auto' }}
          >
            Add custom address
          </button>
        </div>
      </div>
      <div className='h-100 mb-5'>
        <ToolkitProvider
          bootstrap4
          keyField='custodianId'
          columns={columns}
          data={this.state.itemsToDisplay}
        >
          {(props) => (
            <>
              <div>
                <BootstrapTable
                  bordered={false}
                  classes={`table-striped table-hover  mt-4 fs-sm ${s.allCustodiansTable}`}
                  remote={{
                    filter: false,
                    pagination: false,
                    sort: true,
                    cellEdit: false
                  }}
                  noDataIndication={emptyDataMessage}
                  rowClasses={this.rowClasses}
                  {...props.baseProps}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
      <ErrorMessage name='custodians'>
        {(msg) => <div className={s.errorMessage}>{msg}</div>}
      </ErrorMessage>
      <Modal
        size='lg'
        isOpen={this.props.openInternalSelectionModal}
        className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
      >
        <ModalHeader close={customCloseBtn(() => this.closeCustodianModal())}>
          <p className='second-headline-text'>Select Custodians</p>
        </ModalHeader>
        <ModalBody>
          <CustodianSelectionList
            inputRef={this.inputRef}
            setFieldValue={form.setFieldValue}
            setFieldTouched={form.setFieldTouched}
          />
        </ModalBody>
      </Modal>
      <Modal
        size='md'
        isOpen={this.state.openExternalModal}
        className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
      >
        <ModalHeader close={customCloseBtn(() => this.closeExternalCustodianModal())}>
          <p className='second-headline-text'>Please enter the following:</p>
        </ModalHeader>
        <ModalBody>
          <ExternalCustodiansForm
            isChat={true}
            setFieldValue={form.setFieldValue}
            setFieldTouched={form.setFieldTouched}
          />
        </ModalBody>
      </Modal>
    </>
  );
  fourthStep = (form) => (
    <>
      <div className={s.mainContainer}>
        <div
          style={{
            width:
              this.state.internalCustodiansList?.length !== 0 ||
              this.state.containerNames?.length !== 0
                ? '60%'
                : '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between'
          }}
        >
          <div>
            <p className='d-flex mb-1'>
              <span className={s.title}>Hold Name:</span>
              <span className={s.legalHoldName}>{form.values.legalHoldName}</span>
            </p>
            <p className='d-flex mb-1'>
              <span className={s.title}>Description:</span>
              <span className={s.legalHoldDescription}>{form.values.legalHoldDescription}</span>
            </p>
          </div>
          <div className={'mt-3 mb-0'}>
            <p className={`${s.title} mb-3`}>Filter by</p>
            <p className='mb-2'>
              <span className={s.title}>Date :</span>
              <span className={'me-2'}>{dataFormat.dateTimeFormatter(form.values.dateFrom)}</span>
              <span className={s.title}>To:</span>
              <span>{dataFormat.dateTimeFormatter(form.values.dateTo)}</span>
            </p>
          </div>
        </div>
        {form.values?.custodians ||
        this.state.externalCustodiansList?.length !== 0 ||
        this.state.containerNames?.length !== 0 ? (
          <div className={`${s.custodiansContainer} mb-3`}>
            {this.state.containerNames?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>Containers</p>
                <p className={s.custodianNames}>
                  {this.state.containerNames?.map((item) => (
                    <p className='mb-1'>{item}</p>
                  ))}
                </p>
              </div>
            )}
            {this.state.internalCustodiansList?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>Custodians</p>
                <p className={s.custodianNames}>
                  {this.state.showTooltip && (
                    <LegalHoldTooltip
                      currentCustodian={this.state.person}
                      position={this.state.position}
                    />
                  )}
                  {this.state.internalCustodiansList?.map((item, index) => (
                    <p
                      className={s.custodianName}
                      onClick={() => {
                        this.setState({
                          showTooltip: false,
                          person: {}
                        });
                      }}
                    >
                      <span>{item?.displayName}</span>
                      <span
                        style={{
                          position: 'relative',
                          cursor: 'pointer',
                          zIndex: 'auto'
                        }}
                        key={index}
                      >
                        <ImInfo
                          onClick={(e) => {
                            this.handleGetCustodianDetails(item?.custodianId);
                            this.setState({
                              position: {
                                top: e.pageY,
                                left: e.pageX
                              },
                              showTooltip: true
                            });
                          }}
                          className='ms-3 me-2'
                        />
                      </span>
                    </p>
                  ))}
                </p>
              </div>
            )}
            {this.state.externalCustodiansList?.length !== 0 && (
              <div className={s.custodiansList}>
                <p className={`${s.title} mb-1`}>External Custodians</p>
                <p className={s.custodianNames}>
                  {this.state.externalCustodiansList?.map((item) => (
                    <p className='mb-1'>{item}</p>
                  ))}
                </p>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={s.results}>
        <div className={s.resultHeader}>Results</div>
        <div className={s.resultStatsContainer}>
          <div>
            <p className={s.statsLabel}>Files:</p>
            {this.props.legalHoldStats?.isResultValid &&
            this.props.legalHoldStats?.itemCount !== null ? (
              <div className={s.stats}>
                {toReadableSizeFormat.numberWithCommas(this.props.legalHoldStats?.itemCount)}
              </div>
            ) : (
              <div className={s.calculatingText}>
                <span>Calculating... </span>
                <span
                  className='spinner-border text-light ms-1'
                  role='status'
                  style={{ width: '16px', height: '16px' }}
                />
              </div>
            )}
          </div>
          <div>
            <p className={s.statsLabel}>Volume:</p>
            {this.props.legalHoldStats?.isResultValid &&
            this.props.legalHoldStats?.itemSizeInBytes !== null ? (
              <div className={s.stats}>
                {dataFormat.sizeFormatter(this.props.legalHoldStats?.itemSizeInBytes)}
              </div>
            ) : (
              <div className={s.calculatingText}>
                <span>Calculating... </span>
                <span
                  className='spinner-border text-light ms-1'
                  role='status'
                  style={{ width: '16px', height: '16px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  renderStepContent(currentStep, form, columns) {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);
      case 2:
        return this.secondStep(form);
      case 3:
        return this.thirdStep(form, columns);
      case 4:
        return this.fourthStep(form);
      default:
        return this.firstStep(form);
    }
  }

  handleGetCustodianDetails = (id) => {
    const url = `${config.externalApi}/legal/api/Custodian/GetCustodianById?custodianId=${id}`;
    fetch(url, {
      ...defaultOptions
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((details) => {
        this.setState({
          person: details
        });
      })
      .then(() => {
        this.setState({
          showTooltip: true
        });
      })
      .catch((error) => {
        console.error('Error fetching custodian details:', error);
      });
  };

  renderForm() {
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;
    const columns = [
      {
        dataField: 'displayName',
        text: 'Display Name',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Department</p>
            </div>
          );
        },
        formatter: (cell, row) => {
          return (
            <div className='w-100 d-flex align-items-center'>
              {row.isExternal ? (
                <span className='me-3'>
                  <BiCopyright style={{ marginLeft: '8px', width: '18px', height: '18px' }} />
                </span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    this.setState({
                      position: {
                        top: e.pageY,
                        left: e.pageX
                      }
                    });
                  }}
                >
                  <ImInfo
                    onClick={(e) => {
                      this.handleGetCustodianDetails(row?.custodianId);
                      this.setState({
                        showTooltip: true,
                        showIdentitiesTooltip: false,
                        showNameTooltip: false
                      });
                    }}
                    style={{
                      marginRight: '8px',
                      width: '16px',
                      height: '16px',
                      color: '#2d8515'
                    }}
                  />
                </span>
              )}

              <div className='w-100'>
                {row?.isExternal ? (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (row?.displayName?.length > 10) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.displayName?.length > 10) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.displayName?.length <= 10
                      ? row?.displayName
                      : `${row?.displayName?.slice(0, 10)}...`}
                  </p>
                ) : (
                  <p
                    className={`mb-0 fw-semi-bold`}
                    onMouseOver={(e) => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.displayName,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.displayName?.length > 15) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.displayName?.length <= 15
                      ? row?.displayName
                      : `${row?.displayName?.slice(0, 15)}...`}
                  </p>
                )}
                <div className='d-flex'>
                  {row?.isExternal && row?.companyName ? (
                    <p
                      className='mb-0'
                      onMouseOver={(e) => {
                        if (row?.companyName?.length > 10) {
                          this.setState({
                            showNameTooltip: true,
                            longName: row?.companyName,
                            namePosition: {
                              top: e.pageY,
                              left: e.pageX
                            }
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        if (row?.companyName?.length > 10) {
                          this.setState({
                            showNameTooltip: false,
                            longName: '',
                            namePosition: {
                              top: 0,
                              left: 0
                            }
                          });
                        }
                      }}
                    >
                      {row?.companyName?.length <= 10
                        ? row?.companyName
                        : `${row?.companyName?.slice(0, 10)}...`}
                    </p>
                  ) : (
                    <>
                      {row?.department && (
                        <p
                          className='mb-0'
                          onMouseOver={(e) => {
                            if (row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: true,
                                longName: row?.department,
                                namePosition: {
                                  top: e.pageY,
                                  left: e.pageX
                                }
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (row?.department?.length > 15) {
                              this.setState({
                                showNameTooltip: false,
                                longName: '',
                                namePosition: {
                                  top: 0,
                                  left: 0
                                }
                              });
                            }
                          }}
                        >
                          {row?.department?.length <= 15
                            ? row?.department
                            : `${row?.department?.slice(0, 15)}...`}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }
      },
      {
        dataField: 'userId',
        text: 'User Id',
        formatter: (cell, row) => (
          <>
            {cell && (
              <p
                className={`mb-0 fw-semi-bold`}
                onMouseOver={(e) => {
                  if (cell?.length > 10) {
                    this.setState({
                      showNameTooltip: true,
                      longName: cell,
                      namePosition: {
                        top: e.pageY,
                        left: e.pageX
                      }
                    });
                  }
                }}
                onMouseLeave={() => {
                  if (cell?.length > 10) {
                    this.setState({
                      showNameTooltip: false,
                      longName: '',
                      namePosition: {
                        top: 0,
                        left: 0
                      }
                    });
                  }
                }}
              >
                {cell?.length <= 10 ? cell : `${cell?.slice(0, 10)}...`}
              </p>
            )}
          </>
        )
      },
      {
        dataField: 'smtpAddress',
        text: 'Email Address',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>Sam Account</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <div className='d-flex justify-content-between'>
            <div>
              {row?.smtpAddress && (
                <p
                  className={`mb-0 fw-semi-bold`}
                  onMouseOver={(e) => {
                    if (row?.smtpAddress?.length > 30) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.smtpAddress,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.smtpAddress?.length > 30) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.smtpAddress?.length <= 30
                    ? row?.smtpAddress
                    : `${row?.smtpAddress?.slice(0, 30)}...`}
                </p>
              )}
              {!row?.isExternal ? (
                <p
                  className='mb-0'
                  onMouseOver={(e) => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: true,
                        longName: row?.samAccountName,
                        namePosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (row?.samAccountName?.length > 30) {
                      this.setState({
                        showNameTooltip: false,
                        longName: '',
                        namePosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }
                  }}
                >
                  {row?.samAccountName?.length <= 30
                    ? row?.samAccountName
                    : `${row?.samAccountName?.slice(0, 30)}...`}{' '}
                </p>
              ) : (
                <p className='mb-0'>
                  added by: {this.props?.currentUser?.name} {this.props?.currentUser?.surName}{' '}
                </p>
              )}
            </div>
            <div>
              {row?.custodianIdentitiesCount?.map((item) => {
                return (
                  <>
                    <p className='d-flex justify-content-between'>
                      <span className='me-2'>{item.count}</span>
                      <span
                        className={`${
                          item.identityType === 'SMTP' ? s.identitySmtpBox : s.identityX500Box
                        }`}
                        onClick={(e) => {
                          this.handleUserIdentities(row?.custodianId, item?.identityType);
                          this.setState({
                            showIdentitiesTooltip: true,
                            position: {
                              top: e.pageY,
                              left: e.pageX
                            },
                            identityType: item?.identityType,
                            showTooltip: false,
                            showNameTooltip: false,
                            count: item?.count
                          });
                        }}
                      >
                        {item.identityType}
                      </span>
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        )
      },
      {
        dataField: 'upn',
        text: 'Source',
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <p className='mb-0'>{column.text}</p>
              <p className='mb-0 fw-normal'>User principal name</p>
            </div>
          );
        },
        formatter: (cell, row) => (
          <>
            {row.isExternal ? (
              <p className={s.customAddress}>Custom Address</p>
            ) : (
              <div className='w-100'>
                <p className={`mb-0 fw-semi-bold`}>{row?.hasAdRecord ? 'AD' : 'N/A'} </p>
                {row?.upn && (
                  <p
                    className='mb-0'
                    onMouseOver={(e) => {
                      if (row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: true,
                          longName: row?.upn,
                          namePosition: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (row?.upn?.length > 20) {
                        this.setState({
                          showNameTooltip: false,
                          longName: '',
                          namePosition: {
                            top: 0,
                            left: 0
                          }
                        });
                      }
                    }}
                  >
                    {row?.upn?.length <= 20 ? row?.upn : `${row?.upn?.slice(0, 20)}...`}
                  </p>
                )}
              </div>
            )}
          </>
        )
      },
      {
        dataField: 'custodianId',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        }
      }
    ];

    return (
      <div className={s.root}>
        <Row>
          <Col sm={12}>
            <Nav className={s.wizardNavigation}>
              <NavItem>
                <NavLink
                  className={`${s.slackNav} ${classnames({
                    wizardActiveItem: currentStep === 1
                  })}`}
                >
                  <small>1.</small>
                  &nbsp; General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${s.slackNav} ${classnames({
                    wizardActiveItem: currentStep === 2
                  })}`}
                >
                  <small>2.</small>
                  &nbsp; Scope
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${s.slackNav} ${classnames({
                    wizardActiveItem: currentStep === 3
                  })}`}
                >
                  <small>3.</small>
                  &nbsp; Custodians
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${s.slackNav} ${classnames({
                    wizardActiveItem: currentStep === 4
                  })}`}
                >
                  <small>3.</small>
                  &nbsp; Statistics
                </NavLink>
              </NavItem>
            </Nav>
            <Progress value={this.state.progress} className='progress-xs-wizard' />
            <div>
              <div className={s.stepBody}>
                <Formik
                  onSubmit={this.handleSubmit}
                  initialValues={this.iniValues()}
                  validationSchema={this.FormValidationSchema[validateStep]}
                >
                  {(form) => {
                    return (
                      <form onSubmit={form.handleSubmit}>
                        <div
                          style={{
                            minHeight: '400px'
                          }}
                          onClick={() => {
                            this.setState({
                              showTooltip: false,
                              person: {}
                            });
                            if (this.state.showIdentitiesTooltip) {
                              this.setState({
                                showIdentitiesTooltip: false,
                                currentIdentities: []
                              });
                            }
                            if (this.state.showNameTooltip) {
                              this.setState({
                                showNameTooltip: false
                              });
                            }
                          }}
                        >
                          {this.renderStepContent(this.state.currentStep, form, columns)}
                        </div>
                        <div>
                          <ul
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <li className='previous'>
                              <Button
                                hidden={currentStep === 1 || currentStep === 4}
                                color='primary'
                                onClick={this.previousStep}
                                className='first-body-text'
                              >
                                <i className='fa fa-caret-left' />
                                &nbsp;Previous
                              </Button>
                            </li>
                            {currentStep === 3 && this.state.itemsToDisplay?.length !== 0 && (
                              <div className='d-flex mt-1'>
                                {this.state.newRows?.length !== 0 && (
                                  <p className='me-4 mt-2'>Total: {this.state.newRows?.length}</p>
                                )}
                                <div className='d-flex'>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handlePageChange(1);
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'<<'}
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.currentPage !== 1) {
                                        this.handlePageChange(this.state.currentPage - 1);
                                      }
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'<'}
                                  </button>
                                  {this.state.pageNumbers?.map((page) => (
                                    <button
                                      key={page}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handlePageChange(page);
                                      }}
                                      className={
                                        page === this.state.currentPage
                                          ? `${s.activeLinkClass}`
                                          : `${styles.paginationNext}`
                                      }
                                    >
                                      {page}
                                    </button>
                                  ))}
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.currentPage + 1 <= this.state.totalPages) {
                                        this.handlePageChange(this.state.currentPage + 1);
                                      }
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'>'}
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handlePageChange(this.state.totalPages);
                                    }}
                                    className={s.paginationNext}
                                  >
                                    {'>>'}
                                  </button>
                                </div>
                                <div>
                                  <Dropdown
                                    isOpen={this.state.paginationModalOpen}
                                    toggle={() =>
                                      this.setState({
                                        paginationModalOpen: !this.state.paginationModalOpen
                                      })
                                    }
                                    className={styles.pageSizeDropdown}
                                  >
                                    <DropdownToggle className='btn btn-secondary bg-transparent text-white d-flex'>
                                      <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                                        {this.state.itemsPerPage}
                                      </p>
                                      <img
                                        src={caretDown}
                                        alt={'uncollapsed'}
                                        width='20px'
                                        height='20px'
                                        className='ms-2'
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-position-fixed'>
                                      {[5, 10, 25].map((item) => (
                                        <DropdownItem
                                          className={styles.dropdownItem}
                                          key={item}
                                          onClick={() => this.handlePerPageChange(item)}
                                        >
                                          {item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </div>
                            )}
                            <li className='next'>
                              <Button
                                hidden={currentStep === 4}
                                color='primary'
                                onClick={form.handleSubmit}
                                className='first-body-text'
                              >
                                {currentStep === 3 && (
                                  <span>
                                    Run <i className='fa fa-check' />
                                  </span>
                                )}
                                {currentStep < 3 && (
                                  <span>
                                    Next <i className='fa fa-caret-right' />
                                  </span>
                                )}
                              </Button>
                              {currentStep === 4 && (
                                <button
                                  type='button'
                                  hidden={currentStep !== 5}
                                  className='btn d-flex align-items-center first-body-text'
                                  onClick={() => {
                                    this.props.navigate(
                                      `/app/OneDrive/Preview/${this.props?.createdLegalHoldId}`
                                    );
                                    this.props.dispatch(formActions.doCloseFileLH());
                                  }}
                                  disabled={!this.props.legalHoldStats?.isResultValid}
                                >
                                  Preview <i className='fa fa-caret-right ms-2' />
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  render() {
    return this.renderForm();
  }
}

export default OneDriveForm;
