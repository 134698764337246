import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import AttachmentContent from 'components/CRUD/Search/itemContent/AttachmentContent';
import 'components/CRUD/Search/itemContent/fileStyles.css';
import styles from 'components/CRUD/Search/itemContent/styles.module.scss';

class OneDriveFilePreview extends Component {
  state = {
    fileDetails: {},
    decodedHTML: ''
  };

  content = (type, content) => {
    const fileUrl = `data:${type};base64,${content}`;
    return fileUrl;
  };

  fileType = (type) => {
    const formattedFileType = type?.replace(/\./g, '');
    return formattedFileType;
  };

  decodeBase64 = (base64) => {
    try {
      return decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return '';
    }
  };

  render() {
    // HTML to display formatted content
    const formattedContent = this.decodeBase64(this.props.fileDetails?.content);
    return (
      <div
        style={{
          height: '90vh'
        }}
        className='file-preview'
      >
        <div>
          {this.props.fileDetails?.fileType === '.html' && (
            <div
              dangerouslySetInnerHTML={{
                __html: formattedContent
              }}
            ></div>
          )}
          <div style={{ backgroundColor: 'inherit' }}>
            {this.props.fileDetails?.fileType === ('.txt' || '.json') ? (
              <AttachmentContent
                attachment={this.props.fileDetails?.content}
                attachmentType={this.props.fileDetails?.fileType}
              />
            ) : this.props.fileDetails?.fileType === null ? (
              <div className={styles.unsupportedMessage}>
                <p>File format is not supported.</p>
              </div>
            ) : (
              <div className='item-preview'>
                {this.props.fileDetails?.fileType !== '.html' && (
                  <FileViewer
                    fileType={this.fileType(this.props.fileDetails?.fileType)}
                    filePath={this.content(
                      this.props.fileDetails?.fileType,
                      this.props.fileDetails?.content
                    )}
                    wrapperProps={{ className: 'custom-wrapper' }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OneDriveFilePreview;
