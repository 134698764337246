import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from 'reactstrap';
import { FormattingService } from 'utils/sizeFormatting';
import actions from 'actions/Export/ExportListActions';
import { connect } from 'react-redux';
import formActions from 'actions/Export/ExportFormActions';
import ExportJobFormPage from '../form/ExportJobFormPage';
import plusIcon from 'images/icons/plus.svg';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import syncIcon from '../../../../images/icons/sync.svg';
import closeIcon from '../../../../images/icons/close.svg';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import viewIcon from 'images/icons/eye.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import exportStyles from 'components/CRUD/Export/Export.module.scss';
import { customCloseBtn, exportJobTypeEnum } from 'actions/common';

class ChatExportTiles extends Component {
  state = {
    totalCount: 0,
    searchString: '',
    pageNumber: 1,
    pageSize: 3,
    paginationModalOpen: false,
    resetModalOpen: false,
    selectedJob: null,
    newRows: [],
    legalCaseId: this.props?.legalCaseId || '',
    expandExport: false,
    failedItemsModal: false
  };

  handleRetryFailedItems() {
    const { dispatch } = this.props;
    const legalCaseId = this.state.selectedJob?.legalCaseId;
    const exportJobId = this.state.selectedJob?.id;
    dispatch(actions.doRetryFailedItems(legalCaseId, exportJobId, this.props?.currentUser)).then(
      () => {
        this.setState({
          failedItemsModal: false
        });
        dispatch(
          actions.doFetch({
            searchString: this.state.searchString,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            legalCaseId: this.state.legalCaseId,
            exportJobType: exportJobTypeEnum.Chat
          })
        ).then(() => {
          this.setState({
            totalCount: this.props.count,
            newRows: this.props.rows
          });
        });
      }
    );
  }

  closeFailedItemsModal() {
    this.setState({
      failedItemsModal: !this.state.failedItemsModal
    });
  }

  handleResetQueuedItems() {
    const { dispatch } = this.props;
    dispatch(actions.doResetFailedItems(this.state.selectedJob.id, this.props?.currentUser)).then(
      () => {
        this.setState({
          resetModalOpen: false
        });
        dispatch(
          actions.doFetch({
            searchString: this.state.searchString,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            legalCaseId: this.state.legalCaseId,
            exportJobType: exportJobTypeEnum.Chat
          })
        ).then(() => {
          this.setState({
            totalCount: this.props.count,
            newRows: this.props.rows
          });
        });
      }
    );
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  actionFormatter(cell, row) {
    return (
      <div className='d-flex justify-content-end align-items-center w-100'>
        <p style={{ display: !row?.failedItems ? 'none' : 'block', marginBottom: '0px' }}>
          {this.props?.caseStatus !== config.caseStatus.Closed && (
            <button
              className='btn text-white first-body-text d-flex align-items-center me-3'
              type='button'
              onClick={() => {
                this.setState({
                  failedItemsModal: true,
                  selectedJob: row
                });
              }}
            >
              <img src={syncIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2 '} />
              <p className={'mb-0'}>Retry failed items</p>
            </button>
          )}
        </p>
        <p
          style={{
            display:
              row?.isExported || row?.totalItems === 0 || row?.itemsRoutedForExport === 0
                ? 'none'
                : 'block',
            marginBottom: '0px'
          }}
        >
          {this.props?.caseStatus !== config.caseStatus.Closed && (
            <button
              className='btn text-white first-body-text d-flex align-items-center me-3'
              type='button'
              onClick={() => {
                this.setState({
                  resetModalOpen: true,
                  selectedJob: row
                });
              }}
            >
              <img
                src={closeIcon}
                alt={'sync'}
                width={'16px'}
                height={'16px'}
                className={'me-2 '}
              />
              <p className={'mb-0'}>Reset queued items</p>
            </button>
          )}
        </p>
        {this.props.permissions['LegalUI.Chat.Export'] && (
          <button
            className='btn text-white first-body-text d-flex align-items-center '
            type='button'
            onClick={() => {
              this.props.navigate(`/app/Export/${cell}`);
            }}
          >
            <img
              src={viewIcon}
              alt={'details'}
              width={'16px'}
              height={'16px'}
              className={'me-2 '}
            />
            <p className={'mb-0'}>Details</p>
          </button>
        )}
      </div>
    );
  }

  openModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenConfirm());
  }

  closeModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseConfirm());
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId,
        exportJobType: exportJobTypeEnum.Chat
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.state.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          exportJobType: exportJobTypeEnum.Chat
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: this.state.legalCaseId,
        exportJobType: exportJobTypeEnum.Chat
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    if (this.props?.refreshInterval && this.props?.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetch({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          legalCaseId: this.state.legalCaseId,
          exportJobType: exportJobTypeEnum.Chat
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'name',
    'exportPolicyName',
    'created',
    'totalItems',
    'exportedItems',
    'totalSizeInBytes',
    'itemsRoutedForExport',
    'failedItems',
    'statusMessage',
    'exportStatus'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToCsv?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders,
        exportJobTypeEnum.Chat
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/ExportJob/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.state.legalCaseId,
        this.state.searchString,
        columnHeaders,
        exportJobTypeEnum.Chat
      )
    );
  };

  showTable() {
    const { dispatch } = this.props;
    dispatch(actions.doShowTable());
  }

  render() {
    const toReadableSizeFormat = new FormattingService();

    return (
      <>
        <>
          <Row key={'table-part'}>
            <Col style={{ marginTop: '8px' }}>
              {this.props.permissions['LegalUI.Chat.Export.Create'] &&
              this.props?.caseStatus !== config.caseStatus.Closed ? (
                <div className={'d-flex align-items-center'}>
                  {this.props.legalCaseId && (
                    <button
                      className='btn first-body-text d-flex align-items-center'
                      type='button'
                      onClick={() => this.openModal()}
                    >
                      <img src={plusIcon} alt={'plus'} className={'me-2'} />
                      <p className={'mb-0'}>New chat</p>
                    </button>
                  )}
                </div>
              ) : null}
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '5px'
              }}
            >
              <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
              <span>
                <button
                  className='btn fw-semi-bold d-flex align-items-center'
                  type='button'
                  onClick={() => this.showTable()}
                  style={{ height: '37px', marginRight: '5px' }}
                >
                  <FontAwesomeIcon icon={faTable} />
                </button>
              </span>
              <span>
                <input
                  type='search'
                  placeholder={'Search'}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                  style={{
                    border: '0.5px solid #0046b1',
                    borderRadius: '0'
                  }}
                  className={'form-control search-input me-5 w-200'}
                  value={this.state.searchString}
                  onChange={(e) => {
                    this.setState({
                      searchString: e.target.value
                    });
                  }}
                />
              </span>
              <span>
                <button
                  style={{
                    borderRadius: '0',
                    height: '37px',
                    width: '37px'
                  }}
                  className='btn me-2 ms-10'
                  type='button'
                  onClick={this.handleSearch}
                >
                  <img title={'search'} alt={'search'} width={14} height={14} src={searchIcon} />
                </button>
              </span>
            </Col>
          </Row>
          <Row sm={12} className='mt-4'>
            {this.state.newRows?.length ? (
              this.state.newRows?.map((item) => (
                <Col sm={4} key={item.id}>
                  <Widget
                    style={{
                      height: '400px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      width: '100%'
                    }}
                  >
                    <p className={`${exportStyles.cardName} first-headline-text`}>{item.name}</p>
                    <p className='mb-0'>
                      <span className='first-subtitle-text me-2'>Policy:</span>
                      <span className='first-body-text'>{item.exportPolicyName}</span>
                    </p>
                    <p>
                      <span className='first-subtitle-text me-2'>Status:</span>
                      <span className='first-body-text'>{item.statusMessage}</span>
                    </p>
                    <div>
                      <p className={`${styles.sectionHeader} first-subtitle-text`}>Results</p>
                      <div className='d-flex w-100 mb-0'>
                        <p className='w-50 mb-0'>
                          <span className='first-subtitle-text me-2'> Total Items:</span>
                          <span className='first-body-text'>
                            {toReadableSizeFormat.numberWithCommas(item.totalItems)}
                          </span>
                        </p>
                        <p className='w-50 mb-0'>
                          <span className='first-subtitle-text me-2'> Size:</span>
                          <span className='first-body-text'>
                            {dataFormat.sizeFormatter(item.totalSizeInBytes)}
                          </span>
                        </p>
                      </div>
                      <p className='mb-0'>
                        <span className='first-subtitle-text me-2'>Exported Items:</span>
                        <span className='first-body-text'>
                          {toReadableSizeFormat.numberWithCommas(item.exportedItems)}
                        </span>
                      </p>
                      <p className='mb-0'>
                        <span className='first-subtitle-text me-2'>
                          Expected items after export:
                        </span>
                        <span className='first-body-text'>
                          {toReadableSizeFormat.numberWithCommas(item.itemsRoutedForExport)}
                        </span>
                      </p>
                      <p className='mb-0'>
                        <span className='first-subtitle-text me-2'>Failed Items:</span>
                        <span className='first-body-text'>
                          {toReadableSizeFormat.numberWithCommas(item.failedItems)}
                        </span>
                      </p>
                      <p>
                        <span className='first-subtitle-text me-2'>Export Finished:</span>
                        <span className='first-body-text'>
                          {dataFormat.dateTimeFormatter(item.exportFinishedTimestamp)}
                        </span>
                      </p>
                    </div>
                    <p>
                      <span className='first-subtitle-text me-2'>Created by:</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.firstName}</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.lastName}</span>
                      <span>({dataFormat.dateTimeFormatter(item.created)})</span>
                    </p>
                    <p className='d-flex justify-content-end'>
                      {this.actionFormatter(item.id, item)}
                    </p>
                  </Widget>
                </Col>
              ))
            ) : (
              <div
                style={{ height: this.state.newRows?.length ? 500 : 'auto' }}
                className={'d-flex w-100 justify-content-center align-items-center'}
              >
                <p className={'text-white'}>No data available</p>
              </div>
            )}
          </Row>

          <Row key={'pagination'}>
            <Col style={{ marginTop: '3px' }}>
              <p>Total: {this.state.totalCount}</p>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {this.state.totalCount ? (
                <Pagination
                  totalItemsCount={this.state.totalCount}
                  onChange={this.handlePageChange}
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={this.state.pageSize}
                  pageRangeDisplayed={5}
                  prevPageText={'<'}
                  nextPageText={'>'}
                  firstPageText={'<<'}
                  lastPageText={'>>'}
                  linkClassFirst={styles.paginationNext}
                  linkClassPrev={styles.paginationNext}
                  linkClassNext={styles.paginationNext}
                  linkClassLast={styles.paginationNext}
                  linkClass={styles.pageLink}
                  activeLinkClass={styles.activeLinkClass}
                />
              ) : null}
              <Dropdown
                isOpen={this.state.paginationModalOpen}
                toggle={() =>
                  this.setState({
                    paginationModalOpen: !this.state.paginationModalOpen
                  })
                }
                className={styles.pageSizeDropdown}
              >
                <DropdownToggle className='bg-transparent text-white d-flex'>
                  <p
                    style={{ marginBottom: '3px', marginRight: '2px' }}
                    className='first-body-text'
                  >
                    {this.state.pageSize}
                  </p>
                  <img
                    src={caretDown}
                    alt={'uncollapsed'}
                    width='20px'
                    height='20px'
                    className='ms-2'
                  />
                </DropdownToggle>
                <DropdownMenu className='dropdown-position-fixed'>
                  {[3, 6, 9].map((item) => (
                    <DropdownItem
                      className={styles.dropdownItem}
                      key={item}
                      onClick={() => {
                        this.setState({
                          pageSize: item,
                          paginationModalOpen: false,
                          pageNumber: 1
                        });
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Modal
            size='md'
            isOpen={this.props.modalOpen}
            className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
          >
            <ModalHeader close={customCloseBtn(() => this.closeModal())}>
              <p className='second-headline-text'>Create export job</p>
            </ModalHeader>
            <ModalBody>
              <ExportJobFormPage
                legalCaseId={this.props.legalCaseId}
                exportJobType={exportJobTypeEnum.Chat}
              />
            </ModalBody>
          </Modal>
        </>

        <Modal
          size='md'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeResetModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>
              Do you want to reset queued items for {this.state?.selectedJob?.name} export job?
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn first-body-text me-3'
                onClick={() => this.closeResetModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn first-body-text'
                onClick={() => this.handleResetQueuedItems()}
              >
                Reset
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.failedItemsModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFailedItemsModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>
              Do you want to retry failed items for {this.state?.selectedJob?.name} export job?
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn first-body-text me-3'
                onClick={() => this.closeFailedItemsModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn first-body-text'
                onClick={() => this.handleRetryFailedItems()}
              >
                Retry
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.Export.list.rows,
    modalOpen: store.Export.form.modalOpen,
    count: store.Export.list.count,
    permissions: store.auth.permissions,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}
export default connect(mapStateToProps)(ChatExportTiles);
